/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Text } from '@eo-locale/react';
import { withTranslator, withLocalizePath } from '@components/HOC';
import Select from '@components/Forms/partials/Select';
import TextBox from '@components/Forms/partials/TextBox';
import {
    FormErrors,
    validateFields
} from '@components/Forms/partials/FormErrors';
import { getUnLocalizedPath } from '@config/index';
import { defaultFailedResponse } from '@components/auth/fetchData';
import ErrorModal2 from './ErrorModal';
import OtpModal from './OtpModal';
import VehicleSearch from './VehicleSearch';

class Vehicle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formErrors: {},
            transaction_type: 's',
            kms_placeHolder: 'Enter KMs Driven',
            buyText: 'To',
            isTaxi: 0,
            categories:
                typeof this.props.categories !== 'undefined'
                    ? this.props.categories
                    : [],
            errors: '',
            getotp:
                this.props.IsLocalOtp !== undefined
                    ? this.props.IsLocalOtp
                    : this.props.IsLocalOtp,
            getForm: 'newform',
            getPhone: 1111111111,
            postResult: this.props.localizePath('/result'),
            exchange_type: false,
            excepted_kms_placeHolder: 'Exp. Kms Driven/Year',
            tenure_placeHolder:
                this.props.translator.translate('Tenure_(Month)'),
            kms_month_placeHolder:
                this.props.translator.translate('Km_Driven/Month'),
            city_placeHolder: this.props.translator.translate('City'),
            cities: [],
            expYear: [],
            isSubmit: 0,
            searchVehicle: false,
            userType:
                this.props.IsCustType !== undefined
                    ? this.props.IsCustType
                    : 'dealer',
            currentTab: 'Used',
            selectedNewCity: this.props.locationCity
        };

        this.validation = {
            category: {
                input_type: 'select',
                required: 1
            },
            make: {
                input_type: 'select',
                required: 1
            },
            model: {
                input_type: 'select',
                required: 1
            },
            year: {
                input_type: 'select',
                required: 1
            },
            trim: {
                input_type: 'select',
                required: 1
            },
            kms_driven: {
                required: 1,
                min_val: 10,
                max_val: 500000,
                input_type: 'number'
            }
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitNew = this.handleSubmitNew.bind(this);
        this.handleSubmitLease = this.handleSubmitLease.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeNew = this.handleChangeNew.bind(this);
        this.handleChangeLease = this.handleChangeLease.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleTabChangePrice = this.handleTabChangePrice.bind(this);
        this.handleChangeFeatureNew = this.handleChangeFeatureNew.bind(this);
        this.handleSubmitFeatureNew = this.handleSubmitFeatureNew.bind(this);
        this.handleChangeFeatureUsed = this.handleChangeFeatureUsed.bind(this);
        this.handleSubmitFeatureUsed = this.handleSubmitFeatureUsed.bind(this);
        this.handleChangeExchange = this.handleChangeExchange.bind(this);
        this.handleChangeExchangeNew = this.handleChangeExchangeNew.bind(this);
        this.handleSubmitExchange = this.handleSubmitExchange.bind(this);
        this.handleChangeLeaseNew = this.handleChangeLeaseNew.bind(this);
        this.handleSubmitLeaseNew = this.handleSubmitLeaseNew.bind(this);
        this.handleChangeExchangeNewcategory =
            this.handleChangeExchangeNewcategory.bind(this);
        this.handleChangeExchangeUsedcategory =
            this.handleChangeExchangeUsedcategory.bind(this);
        this.handleChangeExchangeUsed =
            this.handleChangeExchangeUsed.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.searchVehicleHandler = this.searchVehicleHandler.bind(this);
        this.resetSearchVehicleHandler =
            this.resetSearchVehicleHandler.bind(this);
        this.newCityChangeHandler = this.newCityChangeHandler.bind(this);
        // this.handleSetOtp            = this.handleSetOtp.bind(this);
    }

    _updateValidation(obj) {
        this.validation[obj.name].value = obj.value;
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (JSON.stringify(newProps) !== JSON.stringify(this.props)) {
            this.setState({
                categories: newProps.categories ? newProps.categories : [],
                getPhone: 1111111111, // (newProps.IsLocalOtp)?newProps.IsLocalOtp:undefined,
                userType: newProps.IsCustType ? newProps.IsCustType : 'dealer',
                postResult: this.props.localizePath('/result'),
                selectedNewCity: newProps.locationCity
            });
        }
    }

    handleSubmit(event) {
        let validator;
        if (this.state.searchVehicle == true) {
            const errs = {
                Category: {}
            };
            validator = validateFields(errs);

            //  let selected_category = document.getElementById('newForm').newcategory.options[document.getElementById('newForm').newcategory.options.selectedIndex].text
            const selected_category =
                document.getElementById('vehicleForm').category.value;
            const selected_make =
                document.getElementById('vehicleForm').make.value;
            const selected_model =
                document.getElementById('vehicleForm').model.value;
            const selected_trim =
                document.getElementById('vehicleForm').trim.value;
            const selected_year =
                document.getElementById('vehicleForm').year.value;
            const selected_kms_driven =
                document.getElementById('vehicleForm').kms_driven.value;
            if (
                selected_category == 'Select Category' ||
                selected_category == 'Undefined' ||
                selected_category == ''
            ) {
                const err = {
                    Vehicle: {
                        // "input_type": "required",
                        required: 1
                    }
                };
                validator = validateFields(err);
            } else if (
                selected_make == 'Select Make' ||
                selected_make == 'Undefined' ||
                selected_make == ''
            ) {
                const err = {
                    Procuct_Title: {
                        required: 1
                    }
                };
                validator = validateFields(err);
            } else if (
                selected_model == 'Select Model' ||
                selected_model == 'Undefined' ||
                selected_model == ''
            ) {
                const err = {
                    Procuct_Title: {
                        required: 1
                    }
                };
                validator = validateFields(err);
            } else if (
                selected_year == 'Select Year' ||
                selected_year == 'Undefined' ||
                selected_year == ''
            ) {
                const err = {
                    Procuct_Title: {
                        // "input_type": "select",
                        required: 1
                    }
                };
                validator = validateFields(err);
            } else if (
                selected_trim == 'Select Trim' ||
                selected_trim == 'Undefined' ||
                selected_trim == ''
            ) {
                const err = {
                    Procuct_Title: {
                        // "input_type": "select",
                        required: 1
                    }
                };
                validator = validateFields(err);
            } else if (
                selected_kms_driven == '' ||
                selected_kms_driven == 'Undefined'
            ) {
                const err = {
                    'kms_driven ': {
                        required: 1,
                        min_val: 10,
                        max_val: 500000,
                        input_type: 'number'
                    }
                };
                validator = validateFields(err);
            } else if (
                selected_kms_driven < 10 ||
                selected_kms_driven > 500000
            ) {
                const err = {
                    'kms_driven between 10-500000': {
                        required: 1,
                        min_val: 10,
                        max_val: 500000,
                        input_type: 'number'
                    }
                };
                validator = validateFields(err);
            }
        } else {
            validator = validateFields(this.validation);
        }

        if (validator.isValid) {
            if (this.state.searchVehicle !== true) {
                if (!this.state.trimStatus[this.validation.trim.value]) {
                    if (this.state.errors) {
                        document.getElementById('errors').style.display =
                            'block';
                    } else {
                        this.setState(
                            {
                                errors: 'T3JhbmdlIEJvb2sgVmFsdWUgTm90IEF2YWlsYWJsZSBmb3IgdGhpcyBwcm9kdWN0'
                            },
                            () => {
                                // callback function for setState
                                ErrorModal2.forceUpdate;
                            }
                        );
                    }
                    event.preventDefault();
                    return false;
                }
            }
            let trans_type =
                document.getElementById('vehicleForm').transaction_type.value;
            const selected_category = 'car'; // document.getElementById('vehicleForm').category.options[document.getElementById('vehicleForm').category.options.selectedIndex].text
            if (trans_type == 'b') {
                trans_type = 'buy';
            } else {
                trans_type = 'sell';
            }
            let selected_customer_type = 'dealer';

            document
                .getElementById('vehicleForm')
                .customer_type.forEach(function (val) {
                    if (val.checked) {
                        selected_customer_type = val.value;
                    }
                });

            if (this.state.isSubmit == 0) {
                this.setState(
                    {
                        // postResult:"/result?"+(selected_category+"_"+trans_type+"_"+selected_customer_type).toLowerCase(),
                        postResult: `${
                            this.state.postResult
                        }?${`${selected_category}_${trans_type}_${selected_customer_type}`.toLowerCase()}`,
                        getotp: this.state.getPhone !== undefined ? '' : 'show',
                        getForm: 'vehicleForm',
                        isSubmit: 1
                    },
                    () => {
                        // submit form
                        this.state.getPhone !== undefined
                            ? document.getElementById('vehicleForm').submit()
                            : '';
                    }
                );
            }

            event.preventDefault();
            return false;
        }
        if (validator.errors) {
            this.setState(
                {
                    formErrors: validator.errors
                },
                () => {
                    // callback function for setState
                    FormErrors.forceUpdate;
                }
            );
            event.preventDefault();
            return false;
        }
        this.setState(
            {
                formErrors: {}
            },
            () => {
                // callback function for setState
                FormErrors.forceUpdate;
            }
        );
    }

    handleChangeTab(event) {
        console.log(event);
        console.log(event.target.getAttribute('data-tab'));
        this.setState({
            currentTab: event.target.getAttribute('data-tab')
        });

        const getTab = event.target.getAttribute('data-tab');
        if (getTab == 'New') {
            fetch(`${this.props.ENV_WEB_URL}/get_city_list`)
                .then(response =>
                    response
                        .json()
                        .catch(
                            /* added in cases where response is not json and dot json call fails */ () =>
                                defaultFailedResponse
                        )
                )
                .then(data => {
                    let options = [];
                    options = Object.keys(data.data);
                    options = options.map(function (word) {
                        return word.charAt(0).toUpperCase() + word.slice(1);
                    });
                    this.setState({
                        cities: options
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
        if (getTab == 'Future Price') {
            let add_url = '';
            add_url += 'api_version=3&category_id=1&times=5';
            fetch(`${this.props.ENV_WEB_URL}/getFutureYear?${add_url}`)
                .then(response =>
                    response
                        .json()
                        .catch(
                            /* added in cases where response is not json and dot json call fails */ () =>
                                defaultFailedResponse
                        )
                )
                .then(data => {
                    let options = [];
                    options = Object.values(data.data.yearList);

                    this.setState({
                        expYear: options
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }

        this.setState(
            {
                formErrors: {}
            },
            () => {
                // callback function for setState
                FormErrors.forceUpdate;
            }
        );
        this.resetSearchVehicleHandler();
        // document.getElementById('forhide').style.display = "none";
    }

    handleSubmitNew(event) {
        let validator;
        const errs = {
            Category: {}
        };
        validator = validateFields(errs);

        if (this.state.searchVehicle == true) {
            var selected_category =
                document.getElementById('newForm').newcategory.value;
            var selected_make =
                document.getElementById('newForm').newmake.value;
            var selected_model =
                document.getElementById('newForm').newmodel.value;
            var selected_trim =
                document.getElementById('newForm').newtrim.value;
            var selected_city =
                document.getElementById('newForm').newcity.options[
                    document.getElementById('newForm').newcity.options
                        .selectedIndex
                ].value;
        } else {
            //  let selected_category = document.getElementById('newForm').newcategory.options[document.getElementById('newForm').newcategory.options.selectedIndex].text
            var selected_category =
                document.getElementById('newForm').newcategory.options[
                    document.getElementById('newForm').newcategory.options
                        .selectedIndex
                ].text;
            var selected_make =
                document.getElementById('newForm').newmake.options[
                    document.getElementById('newForm').newmake.options
                        .selectedIndex
                ].value;
            var selected_model =
                document.getElementById('newForm').newmodel.options[
                    document.getElementById('newForm').newmodel.options
                        .selectedIndex
                ].value;
            var selected_trim =
                document.getElementById('newForm').newtrim.options[
                    document.getElementById('newForm').newtrim.options
                        .selectedIndex
                ].value;
            var selected_city =
                document.getElementById('newForm').newcity.options[
                    document.getElementById('newForm').newcity.options
                        .selectedIndex
                ].value;
        }

        if (
            selected_category == 'श्रेणी चुनें' ||
            selected_category == 'Select Category' ||
            selected_category == 'Undefined' ||
            selected_category == ''
        ) {
            if (this.state.searchVehicle == true) {
                var err = {
                    Vehicle: {
                        input_type: 'select',
                        required: 1
                    }
                };
            } else {
                var err = {
                    Category: {
                        input_type: 'select',
                        required: 1
                    }
                };
            }
            validator = validateFields(err);
        } else if (
            selected_make == 'Select Make' ||
            selected_make == 'मेक चुनें' ||
            selected_make == 'Undefined' ||
            selected_make == ''
        ) {
            const err = {
                Make: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_model == 'Select Model' ||
            selected_make == 'मॉडल चुनें' ||
            selected_model == 'Undefined' ||
            selected_model == ''
        ) {
            const err = {
                Model: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_trim == 'Select Trim' ||
            selected_make == 'ट्रिम चुनें' ||
            selected_trim == 'Undefined' ||
            selected_trim == ''
        ) {
            const err = {
                Trim: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_city == 'Select City' ||
            selected_make == 'शहर चुनें' ||
            selected_city == 'Undefined' ||
            selected_city == ''
        ) {
            const err = {
                City: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        }

        // console.log(this.state.trimStatus);
        // console.log(selected_trim);
        // console.log(this.state.trimStatus[selected_trim])
        // return false;
        if (
            selected_trim != 'Select Trim' &&
            selected_trim != 'ट्रिम चुनें' &&
            selected_trim != '' &&
            selected_trim != 'undefined' &&
            this.state.searchVehicle != true
        ) {
            if (!this.state.trimStatus[selected_trim]) {
                if (this.state.errors) {
                    document.getElementById('errors').style.display = 'block';
                } else {
                    this.setState(
                        {
                            errors: 'T3JhbmdlIEJvb2sgVmFsdWUgTm90IEF2YWlsYWJsZSBmb3IgdGhpcyBwcm9kdWN0'
                        },
                        () => {
                            // callback function for setState
                            ErrorModal2.forceUpdate;
                        }
                    );
                }
                event.preventDefault();
                return false;
            }
        }

        if (validator.errors) {
            this.setState(
                {
                    formErrors: validator.errors
                },
                () => {
                    // callback function for setState
                    FormErrors.forceUpdate;
                }
            );
            event.preventDefault();
            return false;
        }
        this.setState(
            {
                formErrors: {}
            },
            () => {
                // callback function for setState
                FormErrors.forceUpdate;
            }
        );

        const selected_customer_type = '';
        const feature_type = 'new';

        // document.getElementById('newForm').customer_type.forEach(function(val){
        //   if(val.checked){
        //     selected_customer_type=val.value
        //   }
        // });
        if (this.state.isSubmit == 0) {
            this.setState(
                {
                    // postResult:"/result?"+(selected_category+"_"+feature_type).toLowerCase(),
                    postResult: `${
                        this.state.postResult
                    }?${`${selected_category}_${feature_type}`.toLowerCase()}`,
                    getotp: this.state.getPhone !== undefined ? '' : 'show',
                    getForm: 'newForm',
                    isSubmit: 1
                },
                () => {
                    // this.handleSetOtp();
                    // submit form
                    this.state.getPhone !== undefined
                        ? document.getElementById('newForm').submit()
                        : '';
                    // document.getElementById('newForm').submit();
                }
            );
        }
        // event.preventDefault();
        // return false;
        // }
        if (validator.errors) {
            this.setState(
                {
                    formErrors: validator.errors
                },
                () => {
                    // callback function for setState
                    FormErrors.forceUpdate;
                }
            );
            event.preventDefault();
            return false;
        }
        this.setState(
            {
                formErrors: {}
            },
            () => {
                // callback function for setState
                FormErrors.forceUpdate;
            }
        );

        this.setState({
            otp: 'show',
            getForm: 'newForm'
        });
    }

    newCityChangeHandler(event) {
        this.setState({
            selectedNewCity: event.currentTarget.value
        });
    }

    handleSubmitLease(event) {
        let validator;
        const errs = {
            Category: {}
        };

        validator = validateFields(errs);

        if (this.state.searchVehicle == true) {
            var selected_category =
                document.getElementById('leaseForm').leasecategory.value;
            var selected_make =
                document.getElementById('leaseForm').leasemake.value;
            var selected_model =
                document.getElementById('leaseForm').leasemodel.value;
            var selected_trim =
                document.getElementById('leaseForm').leasetrim.value;
            var selected_trim =
                document.getElementById('leaseForm').leaseyear.value;
        } else {
            var selected_category =
                document.getElementById('leaseForm').leasecategory.options[
                    document.getElementById('leaseForm').leasecategory.options
                        .selectedIndex
                ].text;
            var selected_make =
                document.getElementById('leaseForm').leasemake.options[
                    document.getElementById('leaseForm').leasemake.options
                        .selectedIndex
                ].value;
            var selected_model =
                document.getElementById('leaseForm').leasemodel.options[
                    document.getElementById('leaseForm').leasemodel.options
                        .selectedIndex
                ].value;
            var selected_trim =
                document.getElementById('leaseForm').leasetrim.options[
                    document.getElementById('leaseForm').leasetrim.options
                        .selectedIndex
                ].value;
            var selected_year =
                document.getElementById('leaseForm').leaseyear.options[
                    document.getElementById('leaseForm').leaseyear.options
                        .selectedIndex
                ].value;
        }
        const trans_type =
            document.getElementById('leaseForm').vehicletype.value;
        const selected_kms_driven_month =
            document.getElementById('leaseForm').leasekms_driven_month.value;
        const selected_tenure =
            document.getElementById('leaseForm').leasetenure.value;
        const selected_kms_driven =
            document.getElementById('leaseForm').leasekms_driven.value;
        if (
            selected_category == 'Select Category' ||
            selected_category == 'Undefined' ||
            selected_category == ''
        ) {
            if (this.state.searchVehicle == true) {
                var err = {
                    Vehicle: {
                        input_type: 'select',
                        required: 1
                    }
                };
            } else {
                var err = {
                    Category: {
                        input_type: 'select',
                        required: 1
                    }
                };
            }
            validator = validateFields(err);
        } else if (
            selected_make == 'Select Make' ||
            selected_make == 'Undefined' ||
            selected_make == ''
        ) {
            const err = {
                Make: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_model == 'Select Model' ||
            selected_model == 'Undefined' ||
            selected_model == ''
        ) {
            const err = {
                Model: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_year == 'Select Year' ||
            selected_year == 'Undefined' ||
            selected_year == ''
        ) {
            const err = {
                Year: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_trim == 'Select Trim' ||
            selected_trim == 'Undefined' ||
            selected_trim == ''
        ) {
            const err = {
                Trim: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_kms_driven == '' ||
            selected_kms_driven == 'Undefined'
        ) {
            const err = {
                'kms_driven ': {
                    required: 1,
                    min_val: 10,
                    max_val: 500000,
                    input_type: 'number'
                }
            };
            validator = validateFields(err);
        } else if (selected_kms_driven < 10 || selected_kms_driven > 500000) {
            const err = {
                'kms_driven between 10-500000': {
                    required: 1,
                    min_val: 10,
                    max_val: 500000,
                    input_type: 'number'
                }
            };
            validator = validateFields(err);
        } else if (selected_tenure == '' || selected_tenure == 'Undefined') {
            const err = {
                Tenure: {
                    required: 1,
                    min_val: 10,
                    max_val: 500000,
                    input_type: 'number'
                }
            };
            validator = validateFields(err);
        } else if (selected_tenure < 1 || selected_tenure > 84) {
            const err = {
                'Tenure between 1-84 months': {
                    required: 1,
                    min_val: 1,
                    max_val: 84,
                    input_type: 'number'
                }
            };
            validator = validateFields(err);
        } else if (
            selected_kms_driven_month == '' ||
            selected_kms_driven_month == 'Undefined'
        ) {
            const err = {
                'kms_driven Per Months': {
                    required: 1,
                    min_val: 10,
                    max_val: 500000,
                    input_type: 'number'
                }
            };
            validator = validateFields(err);
        } else if (
            selected_kms_driven_month < 10 ||
            selected_kms_driven_month > 500000
        ) {
            const err = {
                'kms_driven Per Months between 10-500000': {
                    required: 1,
                    min_val: 10,
                    max_val: 500000,
                    input_type: 'number'
                }
            };
            validator = validateFields(err);
        } else if (
            selected_trim != 'Select Trim' &&
            selected_trim != '' &&
            selected_trim != 'undefined' &&
            this.state.searchVehicle != true
        ) {
            if (!this.state.trimStatus[selected_trim]) {
                if (this.state.errors) {
                    document.getElementById('errors').style.display = 'block';
                } else {
                    this.setState(
                        {
                            errors: 'T3JhbmdlIEJvb2sgVmFsdWUgTm90IEF2YWlsYWJsZSBmb3IgdGhpcyBwcm9kdWN0'
                        },
                        () => {
                            // callback function for setState
                            ErrorModal2.forceUpdate;
                        }
                    );
                }
                event.preventDefault();
                return false;
            }
        }

        // else if(selected_city=='Select City' || selected_trim=='Undefined' || selected_trim=='')
        // {
        // let err ={
        //   "City" : {
        //      "input_type": "select",
        //     "required": 1
        //   }
        // }
        // validator = validateFields(err);
        // }
        if (validator.errors) {
            this.setState(
                {
                    formErrors: validator.errors
                },
                () => {
                    // callback function for setState
                    FormErrors.forceUpdate;
                }
            );
            event.preventDefault();
            return false;
        }
        this.setState(
            {
                formErrors: {}
            },
            () => {
                // callback function for setState
                FormErrors.forceUpdate;
            }
        );

        //  let selected_customer_type = ""
        const feature_type = 'lease';

        // document.getElementById('leaseForm').customer_type.forEach(function(val){
        //   if(val.checked){
        //     selected_customer_type=val.value
        //   }
        // });
        if (this.state.isSubmit == 0) {
            this.setState(
                {
                    postResult: `${
                        this.state.postResult
                    }?${`${selected_category}_${feature_type}_${trans_type}`.toLowerCase()}`,
                    getotp: 'show',
                    getForm: 'leaseForm',
                    isSubmit: 1
                },
                () => {
                    // submit form
                    this.state.getPhone !== undefined
                        ? document.getElementById('leaseForm').submit()
                        : '';
                    // document.getElementById('leaseForm').submit();
                }
            );
        }
        event.preventDefault();
        return false;
        // }
        if (validator.errors) {
            this.setState(
                {
                    formErrors: validator.errors
                },
                () => {
                    // callback function for setState
                    FormErrors.forceUpdate;
                }
            );
            event.preventDefault();
            return false;
        }
        this.setState(
            {
                formErrors: {}
            },
            () => {
                // callback function for setState
                FormErrors.forceUpdate;
            }
        );
    }

    handleInputChange(event) {
        this.setState({
            formErrors: {}
        });
        event.preventDefault();
    }

    handleSubmitFeatureNew(event) {
        let validator;
        const errs = {
            Category: {}
        };
        validator = validateFields(errs);
        if (this.state.searchVehicle == true) {
            var selected_category =
                document.getElementById('featureFormNew').featurecategorynew
                    .value;
            var selected_make =
                document.getElementById('featureFormNew').featuremakenew.value;
            var selected_model =
                document.getElementById('featureFormNew').featuremodelnew.value;
            var selected_trim =
                document.getElementById('featureFormNew').featuretrimnew.value;
        } else {
            //  let selected_category = document.getElementById('newForm').newcategory.options[document.getElementById('newForm').newcategory.options.selectedIndex].text
            var selected_category =
                document.getElementById('featureFormNew').featurecategorynew
                    .options[
                    document.getElementById('featureFormNew').featurecategorynew
                        .options.selectedIndex
                ].text;
            var selected_make =
                document.getElementById('featureFormNew').featuremakenew
                    .options[
                    document.getElementById('featureFormNew').featuremakenew
                        .options.selectedIndex
                ].value;
            var selected_model =
                document.getElementById('featureFormNew').featuremodelnew
                    .options[
                    document.getElementById('featureFormNew').featuremodelnew
                        .options.selectedIndex
                ].value;
            var selected_trim =
                document.getElementById('featureFormNew').featuretrimnew
                    .options[
                    document.getElementById('featureFormNew').featuretrimnew
                        .options.selectedIndex
                ].value;
        }
        const selected_kms_driven =
            document.getElementById('featureFormNew')
                .featureexpected_kms_drivennew.value;
        const trans_type = 'type'; // document.getElementById('featureFormNew').featuretranstypenew.value;
        const selected_resale =
            document.getElementById('featureFormNew').featureexpectedresalnew
                .options[
                document.getElementById('featureFormNew')
                    .featureexpectedresalnew.options.selectedIndex
            ].value;
        // alert(selected_resale);
        if (
            selected_category == 'Select Category' ||
            selected_category == 'Undefined' ||
            selected_category == ''
        ) {
            if (this.state.searchVehicle == true) {
                var err = {
                    Vehicle: {
                        input_type: 'select',
                        required: 1
                    }
                };
            } else {
                var err = {
                    Category: {
                        input_type: 'select',
                        required: 1
                    }
                };
            }
            validator = validateFields(err);
        } else if (
            selected_make == 'Select Make' ||
            selected_make == 'Undefined' ||
            selected_make == ''
        ) {
            const err = {
                Make: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_model == 'Select Model' ||
            selected_model == 'Undefined' ||
            selected_model == ''
        ) {
            const err = {
                Model: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_trim == 'Select Trim' ||
            selected_trim == 'Undefined' ||
            selected_trim == ''
        ) {
            const err = {
                Trim: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_resale == 'Resale' ||
            selected_resale == 'Undefined' ||
            selected_resale == ''
        ) {
            const err = {
                'Expected Year Of Resale': {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_kms_driven == 'Undefined' ||
            selected_kms_driven == ''
        ) {
            const err = {
                'Expected Kms driven': {
                    required: 1,
                    min_val: 10,
                    max_val: 500000,
                    input_type: 'number'
                }
            };
            validator = validateFields(err);
        } else if (selected_kms_driven < 10 || selected_kms_driven > 500000) {
            const err = {
                'Expected Kms driven between 10-500000': {
                    required: 1,
                    min_val: 10,
                    max_val: 500000,
                    input_type: 'number'
                }
            };
            validator = validateFields(err);
        } else if (
            selected_trim != 'Select Trim' &&
            selected_trim != '' &&
            selected_trim != 'undefined' &&
            this.state.searchVehicle != true
        ) {
            if (!this.state.trimStatus[selected_trim]) {
                if (this.state.errors) {
                    document.getElementById('errors').style.display = 'block';
                } else {
                    this.setState(
                        {
                            errors: 'T3JhbmdlIEJvb2sgVmFsdWUgTm90IEF2YWlsYWJsZSBmb3IgdGhpcyBwcm9kdWN0'
                        },
                        () => {
                            // callback function for setState
                            ErrorModal2.forceUpdate;
                        }
                    );
                }
                event.preventDefault();
                return false;
            }
        }

        if (validator.errors) {
            this.setState(
                {
                    formErrors: validator.errors
                },
                () => {
                    // callback function for setState
                    FormErrors.forceUpdate;
                }
            );
            event.preventDefault();
            return false;
        }
        this.setState(
            {
                formErrors: {}
            },
            () => {
                // callback function for setState
                FormErrors.forceUpdate;
            }
        );

        //  let selected_customer_type = ""
        const feature_type = 'futureprice';

        // document.getElementById('leaseForm').customer_type.forEach(function(val){
        //   if(val.checked){
        //     selected_customer_type=val.value
        //   }
        // });
        if (this.state.isSubmit == 0) {
            this.setState(
                {
                    postResult: `${
                        this.state.postResult
                    }?${`${selected_category}_${feature_type}_${trans_type}`.toLowerCase()}`,
                    getotp: this.state.getPhone !== undefined ? '' : 'show',
                    getForm: 'featureFormNew',
                    isSubmit: 1
                },
                () => {
                    // submit form
                    this.state.getPhone !== undefined
                        ? document.getElementById('featureFormNew').submit()
                        : '';
                    // document.getElementById('featureFormNew').submit();
                }
            );
        }
        event.preventDefault();
        return false;
    }

    handleSubmitLeaseNew(event) {
        let validator;
        const errs = {
            Category: {}
        };
        validator = validateFields(errs);

        //  let selected_category = document.getElementById('newForm').newcategory.options[document.getElementById('newForm').newcategory.options.selectedIndex].text

        // let selected_resale = document.getElementById('leaseFormNew').leaseexpectedresalnew.options[document.getElementById('leaseFormNew').leaseexpectedresalnew.options.selectedIndex].value

        if (this.state.searchVehicle == true) {
            var selected_category =
                document.getElementById('leaseFormNew').leasecategorynew.value;
            var selected_make =
                document.getElementById('leaseFormNew').leasemakenew.value;
            var selected_model =
                document.getElementById('leaseFormNew').leasemodelnew.value;
            var selected_trim =
                document.getElementById('leaseFormNew').leasetrimnew.value;
        } else {
            var selected_category =
                document.getElementById('leaseFormNew').leasecategorynew
                    .options[
                    document.getElementById('leaseFormNew').leasecategorynew
                        .options.selectedIndex
                ].text;
            var selected_make =
                document.getElementById('leaseFormNew').leasemakenew.options[
                    document.getElementById('leaseFormNew').leasemakenew.options
                        .selectedIndex
                ].value;
            var selected_model =
                document.getElementById('leaseFormNew').leasemodelnew.options[
                    document.getElementById('leaseFormNew').leasemodelnew
                        .options.selectedIndex
                ].value;
            var selected_trim =
                document.getElementById('leaseFormNew').leasetrimnew.options[
                    document.getElementById('leaseFormNew').leasetrimnew.options
                        .selectedIndex
                ].value;
        }

        const trans_type =
            document.getElementById('leaseFormNew').vehicletypenew.value;
        const selected_kms_driven =
            document.getElementById('leaseFormNew').leasekms_driven_monthnew
                .value;
        const selected_tenure =
            document.getElementById('leaseFormNew').leasetenurenew.value;

        // alert(selected_model);
        if (
            selected_category == 'Select Category' ||
            selected_category == 'Undefined' ||
            selected_category == ''
        ) {
            if (this.state.searchVehicle == true) {
                var err = {
                    Vehicle: {
                        input_type: 'select',
                        required: 1
                    }
                };
            } else {
                var err = {
                    Category: {
                        input_type: 'select',
                        required: 1
                    }
                };
            }
            validator = validateFields(err);
        } else if (
            selected_make == 'Select Make' ||
            selected_make == 'Undefined' ||
            selected_make == ''
        ) {
            const err = {
                Make: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_model == 'Select Model' ||
            selected_model == 'Undefined' ||
            selected_model == ''
        ) {
            const err = {
                Model: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_trim == 'Select Trim' ||
            selected_trim == 'Undefined' ||
            selected_trim == ''
        ) {
            const err = {
                Trim: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (selected_tenure == 'Undefined' || selected_tenure == '') {
            const err = {
                Tenure: {
                    required: 1,
                    min_val: 10,
                    max_val: 500000,
                    input_type: 'number'
                }
            };
            validator = validateFields(err);
        } else if (selected_tenure < 1 || selected_tenure > 84) {
            const err = {
                'Tenure  between 1-84 months': {
                    required: 1,
                    min_val: 10,
                    max_val: 500000,
                    input_type: 'number'
                }
            };
            validator = validateFields(err);
        } else if (
            selected_kms_driven == 'Undefined' ||
            selected_kms_driven == ''
        ) {
            const err = {
                kms_driven: {
                    required: 1,
                    min_val: 10,
                    max_val: 500000,
                    input_type: 'number'
                }
            };

            validator = validateFields(err);
        } else if (selected_kms_driven < 10 || selected_kms_driven > 500000) {
            const err = {
                'kms_driven  between 10 - 500000': {
                    required: 1,
                    min_val: 10,
                    max_val: 500000,
                    input_type: 'number'
                }
            };
            validator = validateFields(err);
        } else if (
            selected_trim != 'Select Trim' &&
            selected_trim != '' &&
            selected_trim != 'undefined' &&
            this.state.searchVehicle != true
        ) {
            // alert(selected_trim);
            if (!this.state.trimStatus[selected_trim]) {
                if (this.state.errors) {
                    document.getElementById('errors').style.display = 'block';
                } else {
                    this.setState(
                        {
                            errors: 'T3JhbmdlIEJvb2sgVmFsdWUgTm90IEF2YWlsYWJsZSBmb3IgdGhpcyBwcm9kdWN0'
                        },
                        () => {
                            // callback function for setState
                            ErrorModal2.forceUpdate;
                        }
                    );
                }
                event.preventDefault();
                return false;
            }
        }
        if (validator.errors) {
            this.setState(
                {
                    formErrors: validator.errors
                },
                () => {
                    // callback function for setState
                    FormErrors.forceUpdate;
                }
            );
            event.preventDefault();
            return false;
        }
        this.setState(
            {
                formErrors: {}
            },
            () => {
                // callback function for setState
                FormErrors.forceUpdate;
            }
        );

        //  let selected_customer_type = ""
        const feature_type = 'leasenew';

        // document.getElementById('leaseForm').customer_type.forEach(function(val){
        //   if(val.checked){
        //     selected_customer_type=val.value
        //   }
        // });
        if (this.state.isSubmit == 0) {
            this.setState(
                {
                    postResult: `${
                        this.state.postResult
                    }?${`${selected_category}_${feature_type}_${trans_type}`.toLowerCase()}`,
                    getotp: this.state.getPhone !== undefined ? '' : 'show',
                    getForm: 'leaseFormNew',
                    isSubmit: 1
                },
                () => {
                    // submit form
                    this.state.getPhone !== undefined
                        ? document.getElementById('leaseFormNew').submit()
                        : '';
                    // document.getElementById('leaseFormNew').submit();
                }
            );
        }
        event.preventDefault();
        return false;
        // }
        if (validator.errors) {
            this.setState(
                {
                    formErrors: validator.errors
                },
                () => {
                    // callback function for setState
                    FormErrors.forceUpdate;
                }
            );
            event.preventDefault();
            return false;
        }
        this.setState(
            {
                formErrors: {}
            },
            () => {
                // callback function for setState
                FormErrors.forceUpdate;
            }
        );
    }

    handleSubmitFeatureUsed(event) {
        let validator;
        const errs = {
            Category: {}
        };
        validator = validateFields(errs);
        if (this.state.searchVehicle == true) {
            var selected_category =
                document.getElementById('featureFormUsed').featurecategoryused
                    .value;
            var selected_make =
                document.getElementById('featureFormUsed').featuremakeused
                    .value;
            var selected_model =
                document.getElementById('featureFormUsed').featuremodelused
                    .value;
            var selected_trim =
                document.getElementById('featureFormUsed').featuretrimused
                    .value;
            var selected_kms_driven =
                document.getElementById('featureFormUsed').featurekms_drivenused
                    .value;
            var selected_year =
                document.getElementById('featureFormUsed').featureyearused
                    .value;
        } else {
            //  let selected_category = document.getElementById('newForm').newcategory.options[document.getElementById('newForm').newcategory.options.selectedIndex].text
            var selected_category =
                document.getElementById('featureFormUsed').featurecategoryused
                    .options[
                    document.getElementById('featureFormUsed')
                        .featurecategoryused.options.selectedIndex
                ].text;
            var selected_make =
                document.getElementById('featureFormUsed').featuremakeused
                    .options[
                    document.getElementById('featureFormUsed').featuremakeused
                        .options.selectedIndex
                ].value;
            var selected_model =
                document.getElementById('featureFormUsed').featuremodelused
                    .options[
                    document.getElementById('featureFormUsed').featuremodelused
                        .options.selectedIndex
                ].value;
            var selected_trim =
                document.getElementById('featureFormUsed').featuretrimused
                    .options[
                    document.getElementById('featureFormUsed').featuretrimused
                        .options.selectedIndex
                ].value;
            var selected_year =
                document.getElementById('featureFormUsed').featureyearused
                    .options[
                    document.getElementById('featureFormUsed').featureyearused
                        .options.selectedIndex
                ].value;
            var selected_kms_driven =
                document.getElementById('featureFormUsed')
                    .feature_kms_drivenused.value;
        }
        const selected_resale =
            document.getElementById('featureFormUsed').featureexpectedresaleused
                .options[
                document.getElementById('featureFormUsed')
                    .featureexpectedresaleused.options.selectedIndex
            ].value;
        const selected_exp_kms_driven =
            document.getElementById('featureFormUsed')
                .featureexpected_kms_drivenused.value;

        let trans_type = 'buy'; // document.getElementById('featureFormUsed').transaction_type_used.value;

        if (
            selected_category == 'Select Category' ||
            selected_category == 'Undefined' ||
            selected_category == ''
        ) {
            if (this.state.searchVehicle == true) {
                var err = {
                    Vehicle: {
                        input_type: 'select',
                        required: 1
                    }
                };
            } else {
                var err = {
                    Category: {
                        input_type: 'select',
                        required: 1
                    }
                };
            }
            validator = validateFields(err);
        } else if (
            selected_make == 'Select Make' ||
            selected_make == 'Undefined' ||
            selected_make == ''
        ) {
            const err = {
                Make: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_model == 'Select Model' ||
            selected_model == 'Undefined' ||
            selected_model == ''
        ) {
            const err = {
                Model: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_year == 'Select Year' ||
            selected_year == 'Undefined' ||
            selected_year == ''
        ) {
            const err = {
                Year: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_trim == 'Select Trim' ||
            selected_trim == 'Undefined' ||
            selected_trim == ''
        ) {
            const err = {
                Trim: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_kms_driven == 'Undefined' ||
            selected_kms_driven == ''
        ) {
            const err = {
                'Kms driven': {
                    input_type: 'select',
                    required: 1
                }
            };

            validator = validateFields(err);
        } else if (selected_kms_driven < 10 || selected_kms_driven > 500000) {
            const err = {
                'Kms driven between 10-5000000': {
                    input_type: 'select',
                    required: 1
                }
            };

            validator = validateFields(err);
        } else if (
            selected_resale == 'Resale' ||
            selected_resale == 'Undefined' ||
            selected_resale == ''
        ) {
            // alert('Resale');
            const err = {
                'Expected Year Of Resale': {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_exp_kms_driven == 'Undefined' ||
            selected_exp_kms_driven == ''
        ) {
            const err = {
                'Expected Kms driven': {
                    input_type: 'select',
                    required: 1
                }
            };

            validator = validateFields(err);
        } else if (
            selected_exp_kms_driven < 10 ||
            selected_exp_kms_driven > 500000
        ) {
            const err = {
                'Expected Kms driven between 10-500000 ': {
                    input_type: 'select',
                    required: 1
                }
            };

            validator = validateFields(err);
        } else if (
            selected_trim != 'Select Trim' &&
            selected_trim != '' &&
            selected_trim != 'undefined' &&
            this.state.searchVehicle != true
        ) {
            if (!this.state.trimStatus[selected_trim]) {
                if (this.state.errors) {
                    document.getElementById('errors').style.display = 'block';
                } else {
                    this.setState(
                        {
                            errors: 'T3JhbmdlIEJvb2sgVmFsdWUgTm90IEF2YWlsYWJsZSBmb3IgdGhpcyBwcm9kdWN0'
                        },
                        () => {
                            // callback function for setState
                            ErrorModal2.forceUpdate;
                        }
                    );
                }
                event.preventDefault();
                return false;
            }
        }
        if (validator.errors) {
            this.setState(
                {
                    formErrors: validator.errors
                },
                () => {
                    // callback function for setState
                    FormErrors.forceUpdate;
                }
            );
            event.preventDefault();
            return false;
        }
        this.setState(
            {
                formErrors: {}
            },
            () => {
                // callback function for setState
                FormErrors.forceUpdate;
            }
        );

        // alert('s');
        trans_type = 'sell';

        const selected_customer_type = 'dealer';
        const feature_type = 'futureUsed';

        if (this.state.isSubmit == 0) {
            this.setState(
                {
                    postResult: `${
                        this.state.postResult
                    }?${`${selected_category}_${feature_type}_${selected_customer_type}_${trans_type}`.toLowerCase()}`,
                    getotp: 'show',
                    getForm: 'featureFormUsed',
                    isSubmit: 1
                },
                () => {
                    // submit form
                    this.state.getPhone !== undefined
                        ? document.getElementById('featureFormUsed').submit()
                        : '';
                    // document.getElementById('featureFormUsed').submit();
                }
            );
        }
        event.preventDefault();
        return false;
    }

    handleInputChange(event) {
        this.setState({
            formErrors: {}
        });
        event.preventDefault();
    }

    handleChangeNew(event) {
        // console.log('sheetesh');
        const getKey = (obj, val) =>
            Object.keys(obj).find(key => obj[key] === val);
        const _ = this;
        let max_key;
        let add_url = '';
        let element_name_next;
        let element_name = event.target.name;
        let kms_elem;
        const element_value = event.target.value;

        const element_names = ['newcategory', 'newmake', 'newmodel', 'newtrim'];
        if (element_names.indexOf(element_name) > -1) {
            max_key = getKey(element_names, element_name);

            element_names.forEach(function (value, key) {
                if (key <= max_key) {
                    if (
                        document.getElementsByName(value)[0].value != undefined
                    ) {
                        if (
                            parseInt(
                                document.getElementsByName(value)[0].value
                            ) != 0 &&
                            [
                                '',
                                'Select Make',
                                'Select Model',
                                'Select Year'
                            ].indexOf(
                                document.getElementsByName(value)[0].value
                            ) == -1
                        ) {
                            if (value == 'newcategory') {
                                var getName = 'category_id';
                            }
                            if (value == 'newmake') {
                                var getName = 'make';
                            }
                            if (value == 'newmodel') {
                                var getName = 'model';
                            }

                            add_url += `&${
                                getName !== 'undefined' ? getName : value
                            }=${encodeURIComponent(
                                document.getElementsByName(value)[0].value
                            )}`;
                        }
                    }
                } else {
                    if (value == 'newmake') {
                        var getNameText = 'Make';
                    }
                    if (value == 'newmodel') {
                        var getNameText = 'Model';
                    }
                    if (value == 'newtrim') {
                        var getNameText = 'Trim';
                    }
                    document.getElementsByName(
                        value
                    )[0].innerHTML = `<option value=''>${_.props.translator.translate(
                        `Select_${getNameText}`
                    )}</option>`;
                }
            });
            // alert(add_url);

            if (document.getElementsByName('newmodel')[0].value != '') {
                add_url += '&check_obv=1';
            }
            add_url += '&api_version=3';
            const request = new XMLHttpRequest();
            request.open(
                'GET',
                `${this.props.ENV_WEB_URL}/mmt_new?${add_url}`,
                true
            );
            request.onload = function () {
                // console.log(this.response);
                if (this.status >= 200 && this.status < 400) {
                    const data = JSON.parse(this.response);
                    let options = '';

                    switch (element_name) {
                        case 'newcategory':
                            element_name_next = 'newmake';

                            if (element_value == 4) {
                                _.setState({
                                    kms_placeHolder: 'Enter Hrs Flown'
                                });
                            } else {
                                _.setState({
                                    kms_placeHolder: 'Enter KMs Driven'
                                });
                            }
                            if (element_value == 5) {
                                _.setState({
                                    kms_disabled: 'disabled'
                                });
                                _.validation.kms_driven = {};
                            } else {
                                _.setState({
                                    kms_disabled: ''
                                });
                                _.validation.kms_driven = {
                                    required: 1,
                                    min_val: 10,
                                    max_val: 500000,
                                    input_type: 'number'
                                };
                            }

                            break;
                        case 'newmake':
                            element_name_next = 'newmodel';
                            break;
                        case 'newmodel':
                            element_name_next = 'newtrim';
                            _.setState({
                                trimStatus: data.data?.check_obv || {}
                            });
                            data.data = data.data.result;
                            break;
                        default:
                    }

                    element_name = element_value
                        ? element_name_next
                        : element_name;

                    // console.log( element_name);
                    // options+= "<option value=''>Select "+element_name_text+"</option>"
                    const element_name_text = element_name.replace('new', '');
                    options += `<option value=''>${_.props.translator.translate(
                        `Select_${
                            element_name_text.charAt(0).toUpperCase() +
                            element_name_text.slice(1)
                        }`
                    )}</option>`;
                    if (element_name == 'newmake') {
                        // console.log( data);
                        data.data?.length &&
                            data.data.map(function (value) {
                                // console.log( value);
                                if (value.selectable == false) {
                                    options += `<option disabled='disabled' value='${value.name}'> ${value.name}</option>`;
                                } else options += `<option value='${value.name}'> ${value.name}</option>`;
                            });
                    } else {
                        data.data?.length &&
                            data.data.map(function (value) {
                                options += `<option value='${value}'> ${value}</option>`;
                            });
                    }

                    document.getElementsByName(element_name)[0].innerHTML =
                        options;
                } else {
                    console.error('Response received and there was an error');
                }
            };

            request.onerror = function () {
                console.error('Request error');
            };
            request.send();
        }
    }

    handleChangeExchangeNewcategoryOn(event) {
        const getKey = (obj, val) =>
            Object.keys(obj).find(key => obj[key] === val);
        const _ = this;
        let max_key;
        let add_url = '';
        let element_name_next;
        let element_name = event.target.name;
        let kms_elem;
        const element_value = event.target.value;
        if (this.state.searchVehicle != true) {
            const selected_category =
                document.getElementById('exchangeForm').exchangecategory
                    .options[
                    document.getElementById('exchangeForm').exchangecategory
                        .options.selectedIndex
                ].value;
            if (selected_category !== 'undefined' || selected_category !== '') {
                add_url += `category_id=${selected_category}`;
            }
        }
        // alert(element_name)
        const element_names = ['exchangecategory', 'exchangemakenew'];
        if (element_names.indexOf(element_name) > -1) {
            max_key = getKey(element_names, element_name);

            element_names.forEach(function (value, key) {
                if (key <= max_key) {
                    if (
                        document.getElementsByName(value)[0].value != undefined
                    ) {
                        if (
                            parseInt(
                                document.getElementsByName(value)[0].value
                            ) != 0 &&
                            ['', '', 'Select Model', 'Select Year'].indexOf(
                                document.getElementsByName(value)[0].value
                            ) == -1
                        ) {
                            if (value == 'exchangecategory') {
                                var getName = 'category_id';
                            }

                            add_url += `&${
                                getName !== 'undefined' ? getName : value
                            }=${encodeURIComponent(
                                document.getElementsByName(value)[0].value
                            )}`;
                        }
                    }
                } else {
                    if (value == 'exchangemakenew') {
                        var getNameText = 'Make';
                    }

                    document.getElementsByName(
                        value
                    )[0].innerHTML = `<option value=''>${_.props.translator.translate(
                        `Select_${getNameText}`
                    )}</option>`;
                }
            });

            // add_url += '&type=make';

            if (document.getElementsByName('exchangemodelnew')[0].value != '') {
                add_url += '&check_obv=1';
            }
            add_url += '&api_version=3';
            const request = new XMLHttpRequest();
            request.open(
                'GET',
                `${this.props.ENV_WEB_URL}/mmt_new?${add_url}`,
                true
            );
            request.onload = function () {
                if (this.status >= 200 && this.status < 400) {
                    const data = JSON.parse(this.response);
                    let options = '';
                    element_name_next = 'exchangemakenew';

                    element_name = element_value
                        ? element_name_next
                        : element_name;

                    const element_name_text = element_name.replace(
                        /(new|exchange)/g,
                        ''
                    );
                    options += `<option value=''>${_.props.translator.translate(
                        `Select_${
                            element_name_text.charAt(0).toUpperCase() +
                            element_name_text.slice(1)
                        }`
                    )}</option>`;

                    // console.log( data);
                    data.data?.length &&
                        data.data.map(function (value) {
                            options += `<option value='${value.name}'> ${value.name}</option>`;
                        });

                    document.getElementsByName(element_name)[0].innerHTML =
                        options;
                } else {
                    console.error('Response received and there was an error');
                }
            };

            request.onerror = function () {
                console.error('Request error');
            };
            request.send();
        }
    }

    handleChangeExchangeNewcategory(event) {
        // alert('ss');

        const getKey = (obj, val) =>
            Object.keys(obj).find(key => obj[key] === val);
        const _ = this;
        let max_key;
        let add_url = '';
        let element_name_next;
        let element_name = event.target.name;
        let kms_elem;
        const element_value = event.target.value;
        if (this.state.searchVehicle != true) {
            const selected_category =
                document.getElementById('exchangeForm').exchangecategory
                    .options[
                    document.getElementById('exchangeForm').exchangecategory
                        .options.selectedIndex
                ].value;
            if (
                selected_category !== 'undefined' ||
                selected_category !== '' ||
                selected_category !== '0'
            ) {
                add_url += `category_id=${selected_category}`;
            } else {
                return false;
            }
        }

        if (element_name == 'exchange_type') {
            element_name = 'exchangecategory';
        }

        const element_names = ['exchangecategory', 'exchangemakenew'];
        if (element_names.indexOf(element_name) > -1) {
            max_key = getKey(element_names, element_name);

            element_names.forEach(function (value, key) {
                if (key <= max_key) {
                    // alert(element_name);

                    if (
                        document.getElementsByName(value)[0].value != undefined
                    ) {
                        if (
                            parseInt(
                                document.getElementsByName(value)[0].value
                            ) != 0 &&
                            ['', '', 'Select Model', 'Select Year'].indexOf(
                                document.getElementsByName(value)[0].value
                            ) == -1
                        ) {
                            if (value == 'exchangecategory') {
                                var getName = 'category_id';
                            }
                            add_url += `&${
                                getName !== 'undefined' ? getName : value
                            }=${encodeURIComponent(
                                document.getElementsByName(value)[0].value
                            )}`;
                        }
                    }
                } else {
                    // document.getElementsByName('exchangemakenew')[0].innerHTML="<option value=''>Select "+value.charAt(0).toUpperCase() + value.slice(1)+"</option>";
                }
            });

            // add_url += '&type=make';

            if (document.getElementsByName('exchangemodelnew')[0].value != '') {
                add_url += '&check_obv=1';
            }
            add_url += '&api_version=3';
            const request = new XMLHttpRequest();
            request.open(
                'GET',
                `${this.props.ENV_WEB_URL}/mmt_new?${add_url}`,
                true
            );
            request.onload = function () {
                if (this.status >= 200 && this.status < 400) {
                    const data = JSON.parse(this.response);
                    let options = '';
                    element_name_next = 'exchangemakenew';

                    element_name = element_value
                        ? element_name_next
                        : element_name;

                    const element_name_text = element_name.replace(
                        /(new|exchange)/g,
                        ''
                    );
                    options += `<option value=''>${_.props.translator.translate(
                        `Select_${
                            element_name_text.charAt(0).toUpperCase() +
                            element_name_text.slice(1)
                        }`
                    )}</option>`;

                    data.data?.length &&
                        data.data.map(function (value) {
                            options += `<option value='${value.name}'> ${value.name}</option>`;
                        });

                    document.getElementsByName(element_name)[0].innerHTML =
                        options;
                } else {
                    console.error('Response received and there was an error');
                }
            };

            request.onerror = function () {
                console.error('Request error');
            };
            request.send();
        }
    }

    handleChangeExchangeUsedcategory(event) {
        const getKey = (obj, val) =>
            Object.keys(obj).find(key => obj[key] === val);
        const _ = this;
        let max_key;
        let add_url = '';
        let element_name_next;
        let element_name = event.target.name;
        let kms_elem;
        const element_value = event.target.value;
        if (this.state.searchVehicle != true) {
            const selected_category =
                document.getElementById('exchangeForm').exchangecategory
                    .options[
                    document.getElementById('exchangeForm').exchangecategory
                        .options.selectedIndex
                ].value;
            // alert(selected_category);
            if (
                selected_category !== 'undefined' ||
                selected_category !== '' ||
                selected_category !== 0
            ) {
                add_url += `category_id=${selected_category}`;
            } else {
                return false;
            }
        }

        if (element_name == 'exchange_type') {
            element_name = 'exchangecategory';
        }
        // alert(element_name);
        const element_names = ['exchangecategory', 'exchangemakenew'];
        if (element_names.indexOf(element_name) > -1) {
            max_key = getKey(element_names, element_name);

            element_names.forEach(function (value, key) {
                if (key <= max_key) {
                    // alert(element_name);
                    // console.log(value);
                    if (
                        document.getElementsByName(value)[0].value != undefined
                    ) {
                        if (
                            parseInt(
                                document.getElementsByName(value)[0].value
                            ) != 0 &&
                            ['', '', 'Select Model', 'Select Year'].indexOf(
                                document.getElementsByName(value)[0].value
                            ) == -1
                        ) {
                            if (value == 'exchangecategory') {
                                var getName = 'category_id';
                            }
                            add_url += `&${
                                getName !== 'undefined' ? getName : value
                            }=${encodeURIComponent(
                                document.getElementsByName(value)[0].value
                            )}`;
                        }
                    }
                } else {
                    const valueText = value.replace(/(new|exchange)/g, '');
                    document.getElementsByName(
                        value
                    )[0].innerHTML = `<option value=''>${_.props.translator.translate(
                        `Select_${valueText
                            .charAt(0)
                            .toUpperCase()}${valueText.slice(1)}`
                    )}</option>`;
                }
            });

            // add_url += '&type=make';

            if (document.getElementsByName('year')[0].value != '') {
                add_url += '&check_obv=1';
            }
            add_url += '&api_version=3';
            const request = new XMLHttpRequest();

            request.open(
                'GET',
                `${this.props.ENV_WEB_URL}/mmyt?${add_url}`,
                true
            );
            // request.open('GET', '//'+window.location.hostname+'/mmyt?'+add_url, true);

            request.onload = function () {
                if (this.status >= 200 && this.status < 400) {
                    const data = JSON.parse(this.response);
                    let options = '';
                    element_name_next = 'exchangemakenew';

                    element_name = element_value
                        ? element_name_next
                        : element_name;

                    const element_name_text = element_name.replace(
                        /(new|exchange)/g,
                        ''
                    );
                    options += `<option value=''>${_.props.translator.translate(
                        `Select_${
                            element_name_text.charAt(0).toUpperCase() +
                            element_name_text.slice(1)
                        }`
                    )}</option>`;

                    // console.log(element_name);
                    data.data?.length &&
                        data.data.map(function (value) {
                            options += `<option value='${value.name}'> ${value.name}</option>`;
                        });
                    // alert(options)
                    document.getElementsByName(element_name)[0].innerHTML = '';
                    document.getElementsByName(element_name)[0].innerHTML =
                        options;
                    // this.handleChangeExchangeUsedcategodocument.getElementsByName(value)[0].innerHTML="<option value=''>Select "+getNameText+"</option>";ry
                } else {
                    console.error('Response received and there was an error');
                }
            };

            request.onerror = function () {
                console.error('Request error');
            };
            request.send();
        }
    }

    handleChangeExchangeNew(event) {
        const getKey = (obj, val) =>
            Object.keys(obj).find(key => obj[key] === val);
        const _ = this;
        let max_key;
        let add_url = '';
        let element_name_next;
        let element_name = event.target.name;
        const element_value = event.target.value;
        const selected_category =
            document.getElementById('exchangeForm').exchangecategory.options[
                document.getElementById('exchangeForm').exchangecategory.options
                    .selectedIndex
            ].value;
        if (selected_category !== 'undefined' || selected_category !== '') {
            add_url += `category_id=${selected_category}`;
        }

        const element_names = [
            'exchangemakenew',
            'exchangemodelnew',
            'exchangetrimnew'
        ];
        if (element_names.indexOf(element_name) > -1) {
            max_key = getKey(element_names, element_name);

            element_names.forEach(function (value, key) {
                if (key <= max_key) {
                    if (
                        document.getElementsByName(value)[0].value != undefined
                    ) {
                        if (
                            parseInt(
                                document.getElementsByName(value)[0].value
                            ) != 0 &&
                            ['', '', 'Select Model', 'Select Year'].indexOf(
                                document.getElementsByName(value)[0].value
                            ) == -1
                        ) {
                            if (value == 'exchangemakenew') {
                                var getName = 'make';
                            }
                            if (value == 'exchangemodelnew') {
                                var getName = 'model';
                            }

                            add_url += `&${
                                getName !== 'undefined' ? getName : value
                            }=${encodeURIComponent(
                                document.getElementsByName(value)[0].value
                            )}`;
                        }
                    }
                } else {
                    if (add_url.match(/category_id=7($|&)/)) {
                        add_url = add_url.replace(
                            '&category_id=7',
                            '&category_id=1'
                        );
                        _.setState({
                            isTaxi: 1
                        });
                    } else {
                        _.setState({
                            isTaxi: 0
                        });
                    }

                    if (value == 'exchangemakenew') {
                        var getNameText = 'Make';
                    }
                    if (value == 'exchangemodelnew') {
                        var getNameText = 'Model';
                    }
                    if (value == 'exchangetrimnew') {
                        var getNameText = 'Trim';
                    }
                    document.getElementsByName(
                        value
                    )[0].innerHTML = `<option value=''>${_.props.translator.translate(
                        `Select_${getNameText}`
                    )}</option>`;
                }
            });

            if (document.getElementsByName('exchangemodelnew')[0].value != '') {
                add_url += '&check_obv=1';
            }
            add_url += '&api_version=3';
            const request = new XMLHttpRequest();
            request.open(
                'GET',
                `${this.props.ENV_WEB_URL}/mmt_new?${add_url}`,
                true
            );
            request.onload = function () {
                if (this.status >= 200 && this.status < 400) {
                    const data = JSON.parse(this.response);
                    let options = '';

                    switch (element_name) {
                        case 'exchangemakenew':
                            element_name_next = 'exchangemodelnew';
                            break;

                        case 'exchangemodelnew':
                            element_name_next = 'exchangetrimnew';
                            _.setState({
                                trimStatus: data.data?.check_obv || {}
                            });
                            data.data = data.data.result;
                            break;
                        default:
                    }

                    element_name = element_value
                        ? element_name_next
                        : element_name;

                    const element_name_text = element_name.replace(
                        /(new|exchange)/g,
                        ''
                    );
                    options += `<option value=''>${_.props.translator.translate(
                        `Select_${
                            element_name_text.charAt(0).toUpperCase() +
                            element_name_text.slice(1)
                        }`
                    )}</option>`;

                    if (element_name == 'exchangemakenew') {
                        data.data?.length &&
                            data.data.map(function (value) {
                                if (value.selectable == false) {
                                    options += `<option disabled='disabled' value='${value.name}'> ${value.name}</option>`;
                                } else options += `<option value='${value.name}'> ${value.name}</option>`;
                            });
                    } else {
                        data.data?.length &&
                            data.data.map(function (value) {
                                options += `<option value='${value}'> ${value}</option>`;
                            });
                    }

                    document.getElementsByName(element_name)[0].innerHTML =
                        options;
                } else {
                    console.error('Response received and there was an error');
                }
            };

            request.onerror = function () {
                console.error('Request error');
            };
            request.send();
        }
    }

    handleChangeExchangeUsed(event) {
        const getKey = (obj, val) =>
            Object.keys(obj).find(key => obj[key] === val);
        const _ = this;
        let max_key;
        let add_url = '';
        let element_name_next;
        let element_name = event.target.name;
        let kms_elem;
        const element_value = event.target.value;
        const element_names = [
            'exchangemakeused',
            'exchangemodelused',
            'exchangeyearused',
            'exchangetrimused'
        ];
        const selected_category =
            document.getElementById('exchangeForm').exchangecategory.options[
                document.getElementById('exchangeForm').exchangecategory.options
                    .selectedIndex
            ].value;
        if (selected_category !== 'undefined' || selected_category !== '') {
            add_url += `category_id=${selected_category}`;
        }
        if (element_names.indexOf(element_name) > -1) {
            max_key = getKey(element_names, element_name);
            element_names.forEach(function (value, key) {
                if (key <= max_key) {
                    if (
                        document.getElementsByName(value)[0].value != undefined
                    ) {
                        if (
                            parseInt(
                                document.getElementsByName(value)[0].value
                            ) != 0 &&
                            [
                                '',
                                'Select Make',
                                'Select Model',
                                'Select Year'
                            ].indexOf(
                                document.getElementsByName(value)[0].value
                            ) == -1
                        ) {
                            if (value == 'exchangemakeused') {
                                var getName = 'make';
                            }
                            if (value == 'exchangemodelused') {
                                var getName = 'model';
                            }
                            if (value == 'exchangeyearused') {
                                var getName = 'year';
                            }

                            add_url += `&${
                                getName !== 'undefined' ? getName : value
                            }=${encodeURIComponent(
                                document.getElementsByName(value)[0].value
                            )}`;
                        }
                    }
                } else {
                    if (value == 'exchangemakeused') {
                        var getNameText = 'Make';
                    }
                    if (value == 'exchangemodelused') {
                        var getNameText = 'Model';
                    }
                    if (value == 'exchangeyearused') {
                        var getNameText = 'Year';
                    }
                    if (value == 'exchangetrimused') {
                        var getNameText = 'Trim';
                    }

                    document.getElementsByName(
                        value
                    )[0].innerHTML = `<option value=''>${_.props.translator.translate(
                        `Select_${getNameText}`
                    )}</option>`;
                }
            });
            if (document.getElementsByName('exchangeyearused')[0].value != '') {
                add_url += '&check_obv=1';
            }
            add_url += '&api_version=3';
            const request = new XMLHttpRequest();
            request.open(
                'GET',
                `${this.props.ENV_WEB_URL}/mmyt?${add_url}`,
                true
            );
            // request.open('GET', '//'+window.location.hostname+'/mmyt?'+add_url, true);
            request.onload = function () {
                if (this.status >= 200 && this.status < 400) {
                    const data = JSON.parse(this.response);
                    let options = '';

                    switch (element_name) {
                        case 'exchangemakeused':
                            element_name_next = 'exchangemodelused';
                            break;
                        case 'exchangemodelused':
                            element_name_next = 'exchangeyearused';
                            break;
                        case 'exchangeyearused':
                            element_name_next = 'exchangetrimused';
                            _.setState({
                                trimStatus: data.data?.check_obv || {}
                            });
                            data.data = data.data.result;
                            break;
                        default:
                    }

                    element_name = element_value
                        ? element_name_next
                        : element_name;

                    const element_name_text = element_name.replace(
                        /(used|exchange)/g,
                        ''
                    );
                    options += `<option value=''>${_.props.translator.translate(
                        `Select_${
                            element_name_text.charAt(0).toUpperCase() +
                            element_name_text.slice(1)
                        }`
                    )}</option>`;

                    if (element_name == 'exchangemakeused') {
                        data.data?.length &&
                            data.data.map(function (value) {
                                if (value.selectable == false) {
                                    options += `<option disabled='disabled' value='${value.name}'> ${value.name}</option>`;
                                } else options += `<option value='${value.name}'> ${value.name}</option>`;
                            });
                    } else {
                        data.data?.length &&
                            data.data.map(function (value) {
                                options += `<option value='${value}'> ${value}</option>`;
                            });
                    }

                    document.getElementsByName(element_name)[0].innerHTML =
                        options;
                } else {
                    console.error('Response received and there was an error');
                }
            };

            request.onerror = function () {
                console.error('Request error');
            };
            request.send();
        }
    }

    handleSubmitExchange(event) {
        let validator;
        const errs = {
            Category: {}
        };
        // alert('s');
        validator = validateFields(errs);
        if (this.state.searchVehicle == true) {
            var selected_category =
                document.getElementById('exchangeForm').exchangecategory.value;
            var selected_make =
                document.getElementById('exchangeForm').exchangemake.value;
            var selected_model =
                document.getElementById('exchangeForm').exchangemodel.value;
            var selected_trim =
                document.getElementById('exchangeForm').exchangetrim.value;
            var selected_year =
                document.getElementById('exchangeForm').exchangeyear.value;
            var selected_kms_driven =
                document.getElementById('exchangeForm').exchangekms_driven
                    .value;
        } else {
            var selected_kms_driven =
                document.getElementById('exchangeForm').exchangekms_driven
                    .value;
            var selected_category =
                document.getElementById('exchangeForm').exchangecategory
                    .options[
                    document.getElementById('exchangeForm').exchangecategory
                        .options.selectedIndex
                ].text;
            var selected_make =
                document.getElementById('exchangeForm').exchangemake.options[
                    document.getElementById('exchangeForm').exchangemake.options
                        .selectedIndex
                ].value;
            var selected_model =
                document.getElementById('exchangeForm').exchangemodel.options[
                    document.getElementById('exchangeForm').exchangemodel
                        .options.selectedIndex
                ].value;
            var selected_trim =
                document.getElementById('exchangeForm').exchangetrim.options[
                    document.getElementById('exchangeForm').exchangetrim.options
                        .selectedIndex
                ].value;
            var selected_year =
                document.getElementById('exchangeForm').exchangeyear.options[
                    document.getElementById('exchangeForm').exchangeyear.options
                        .selectedIndex
                ].value;
        }
        let selected_exchange_type = '';
        document
            .getElementById('exchangeForm')
            .exchange_type.forEach(function (val) {
                if (val.checked) {
                    selected_exchange_type = val.value;
                }
            });

        if (
            selected_category == 'Select Category' ||
            selected_category == 'Undefined' ||
            selected_category == ''
        ) {
            if (this.state.searchVehicle == true) {
                var err = {
                    Vehicle: {
                        input_type: 'select',
                        required: 1
                    }
                };
            } else {
                var err = {
                    Category: {
                        input_type: 'select',
                        required: 1
                    }
                };
            }
            validator = validateFields(err);
        } else if (
            selected_make == 'Select Make' ||
            selected_make == 'Undefined' ||
            selected_make == ''
        ) {
            const err = {
                Make: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_model == 'Select Model' ||
            selected_model == 'Undefined' ||
            selected_model == ''
        ) {
            const err = {
                Model: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_year == 'Select Year' ||
            selected_year == 'Undefined' ||
            selected_year == ''
        ) {
            const err = {
                Year: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_trim == 'Select Trim' ||
            selected_trim == 'Undefined' ||
            selected_trim == ''
        ) {
            const err = {
                Trim: {
                    input_type: 'select',
                    required: 1
                }
            };
            validator = validateFields(err);
        } else if (
            selected_kms_driven == '' ||
            selected_kms_driven == 'Undefined'
        ) {
            const err = {
                kms_driven: {
                    required: 1,
                    min_val: 10,
                    max_val: 500000,
                    input_type: 'number'
                }
            };
            validator = validateFields(err);
        } else if (selected_kms_driven < 10 || selected_kms_driven > 500000) {
            const err = {
                'kms_driven between 10-500000': {
                    required: 1,
                    min_val: 10,
                    max_val: 500000,
                    input_type: 'number'
                }
            };
            validator = validateFields(err);
        } else if (selected_exchange_type == 'exchange_with_new') {
            if (this.state.searchVehicle == true) {
                // var selected_category = document.getElementById('exchangeForm').exchangecategory.value
                var selected_make_new =
                    document.getElementById('exchangeForm').exchangemakenew
                        .value;
                var selected_model_new =
                    document.getElementById('exchangeForm').exchangemodelnew
                        .value;
                var selected_trim_new =
                    document.getElementById('exchangeForm').exchangetrimnew
                        .value;
            } else {
                var selected_make_new =
                    document.getElementById('exchangeForm').exchangemakenew
                        .options[
                        document.getElementById('exchangeForm').exchangemakenew
                            .options.selectedIndex
                    ].value;
                var selected_model_new =
                    document.getElementById('exchangeForm').exchangemodelnew
                        .options[
                        document.getElementById('exchangeForm').exchangemodelnew
                            .options.selectedIndex
                    ].value;
                var selected_trim_new =
                    document.getElementById('exchangeForm').exchangetrimnew
                        .options[
                        document.getElementById('exchangeForm').exchangetrimnew
                            .options.selectedIndex
                    ].value;
            }
            if (
                selected_make_new == 'Select Make' ||
                selected_make_new == 'Undefined' ||
                selected_make_new == ''
            ) {
                const err = {
                    'Make for New': {
                        input_type: 'select',
                        required: 1
                    }
                };
                validator = validateFields(err);
            } else if (
                selected_model_new == 'Select Model' ||
                selected_model_new == 'Undefined' ||
                selected_model_new == ''
            ) {
                const err = {
                    'Model for New': {
                        input_type: 'select',
                        required: 1
                    }
                };
                validator = validateFields(err);
            } else if (
                selected_trim_new == 'Select Trim' ||
                selected_trim_new == 'Undefined' ||
                selected_trim_new == ''
            ) {
                const err = {
                    'Trim For New': {
                        input_type: 'select',
                        required: 1
                    }
                };
                validator = validateFields(err);
            } else if (
                selected_trim_new != 'Select Trim' &&
                selected_trim_new != '' &&
                selected_trim_new != undefined &&
                this.state.searchVehicle != true
            ) {
                if (!this.state.trimStatus[selected_trim_new]) {
                    if (this.state.errors) {
                        document.getElementById('errors').style.display =
                            'block';
                    } else {
                        this.setState(
                            {
                                errors: 'T3JhbmdlIEJvb2sgVmFsdWUgTm90IEF2YWlsYWJsZSBmb3IgdGhpcyBwcm9kdWN0'
                            },
                            () => {
                                // callback function for setState
                                ErrorModal2.forceUpdate;
                            }
                        );
                    }
                    event.preventDefault();
                    return false;
                }
            }
        } else if (selected_exchange_type == 'exchange_with_used') {
            if (this.state.searchVehicle == true) {
                // var selected_category = document.getElementById('exchangeForm').exchangecategory.value
                var selected_make_used =
                    document.getElementById('exchangeForm').exchangemakeused
                        .value;
                var selected_model_used =
                    document.getElementById('exchangeForm').exchangemodelused
                        .value;
                var selected_trim_used =
                    document.getElementById('exchangeForm').exchangetrimused
                        .value;
                var selected_year_used =
                    document.getElementById('exchangeForm').exchangeyearused
                        .value;
                var selected_kms_driven_used =
                    document.getElementById('exchangeForm')
                        .exchangekms_drivenused.value;
            } else {
                var selected_make_used =
                    document.getElementById('exchangeForm').exchangemakeused
                        .options[
                        document.getElementById('exchangeForm').exchangemakeused
                            .options.selectedIndex
                    ].value;
                var selected_model_used =
                    document.getElementById('exchangeForm').exchangemodelused
                        .options[
                        document.getElementById('exchangeForm')
                            .exchangemodelused.options.selectedIndex
                    ].value;
                var selected_trim_used =
                    document.getElementById('exchangeForm').exchangetrimused
                        .options[
                        document.getElementById('exchangeForm').exchangetrimused
                            .options.selectedIndex
                    ].value;
                var selected_year_used =
                    document.getElementById('exchangeForm').exchangeyear
                        .options[
                        document.getElementById('exchangeForm').exchangeyear
                            .options.selectedIndex
                    ].value;
                var selected_kms_driven_used =
                    document.getElementById('exchangeForm')
                        .exchangekms_drivenused.value;
            }
            if (
                selected_make_used == 'Select Make' ||
                selected_make_used == 'Undefined' ||
                selected_make_used == ''
            ) {
                const err = {
                    'Make for Used': {
                        input_type: 'select',
                        required: 1
                    }
                };
                validator = validateFields(err);
            } else if (
                selected_model_used == 'Select Model' ||
                selected_model_used == 'Undefined' ||
                selected_model_used == ''
            ) {
                const err = {
                    'Model for Used': {
                        input_type: 'select',
                        required: 1
                    }
                };
                validator = validateFields(err);
            } else if (
                selected_trim_used == 'Select Trim' ||
                selected_trim_used == 'Undefined' ||
                selected_trim_used == ''
            ) {
                const err = {
                    'Trim For Used': {
                        input_type: 'select',
                        required: 1
                    }
                };
                validator = validateFields(err);
            } else if (
                selected_year_used == 'Select Year' ||
                selected_year_used == 'Undefined' ||
                selected_year_used == ''
            ) {
                const err = {
                    'Year for Used': {
                        input_type: 'select',
                        required: 1
                    }
                };
                validator = validateFields(err);
            } else if (
                selected_kms_driven_used == '' ||
                selected_kms_driven_used == 'Undefined'
            ) {
                const err = {
                    'kms_driven For Used': {
                        required: 1,
                        min_val: 10,
                        max_val: 500000,
                        input_type: 'number'
                    }
                };
                validator = validateFields(err);
            } else if (
                selected_kms_driven_used < 10 ||
                selected_kms_driven_used > 500000
            ) {
                const err = {
                    'kms_driven between 1-500000': {
                        required: 1,
                        min_val: 10,
                        max_val: 500000,
                        input_type: 'number'
                    }
                };
                validator = validateFields(err);
            } else if (
                selected_trim_used != 'Select Trim' &&
                selected_trim_used != '' &&
                selected_trim_used != undefined &&
                this.state.searchVehicle != true
            ) {
                if (!this.state.trimStatus[selected_trim_used]) {
                    if (this.state.errors) {
                        document.getElementById('errors').style.display =
                            'block';
                    } else {
                        this.setState(
                            {
                                errors: 'T3JhbmdlIEJvb2sgVmFsdWUgTm90IEF2YWlsYWJsZSBmb3IgdGhpcyBwcm9kdWN0'
                            },
                            () => {
                                // callback function for setState
                                ErrorModal2.forceUpdate;
                            }
                        );
                    }
                    event.preventDefault();
                    return false;
                }
            }
        }

        if (validator.errors) {
            this.setState(
                {
                    formErrors: validator.errors
                },
                () => {
                    // callback function for setState
                    FormErrors.forceUpdate;
                }
            );
            event.preventDefault();
            return false;
        }
        this.setState(
            {
                formErrors: {}
            },
            () => {
                // callback function for setState
                FormErrors.forceUpdate;
            }
        );

        // if(trans_type == 'b'){
        //   trans_type="buy"
        // }else{
        //   trans_type="sell"
        // }
        // let selected_customer_type = ""
        const feature_type = 'exchange';

        // document.getElementById('exchangeForm').customer_type_value.forEach(function(val){
        //   if(val.checked){
        //     selected_customer_type=val.value
        //   }
        // });

        if (this.state.isSubmit == 0) {
            this.setState(
                {
                    postResult: `${
                        this.state.postResult
                    }?${`${selected_category}_${feature_type}`.toLowerCase()}`,
                    getotp: 'show',
                    getForm: 'exchangeForm',
                    isSubmit: 1
                },
                () => {
                    // submit form

                    this.state.getPhone !== undefined
                        ? document.getElementById('exchangeForm').submit()
                        : '';
                    // document.getElementById('exchangeForm').submit();
                }
            );
        }
        event.preventDefault();
        return false;
        // }
        if (validator.errors) {
            this.setState(
                {
                    formErrors: validator.errors
                },
                () => {
                    // callback function for setState
                    FormErrors.forceUpdate;
                }
            );
            event.preventDefault();
            return false;
        }
        this.setState(
            {
                formErrors: {}
            },
            () => {
                // callback function for setState
                FormErrors.forceUpdate;
            }
        );
    }

    handleChangeFeatureNew(event) {
        const getKey = (obj, val) =>
            Object.keys(obj).find(key => obj[key] === val);
        const _ = this;
        let max_key;
        let add_url = '';
        let element_name_next;
        let element_name = event.target.name;
        let kms_elem;
        const element_value = event.target.value;

        const element_names = [
            'featurecategorynew',
            'featuremakenew',
            'featuremodelnew',
            'featuretrimnew'
        ];
        if (element_names.indexOf(element_name) > -1) {
            max_key = getKey(element_names, element_name);

            element_names.forEach(function (value, key) {
                if (key <= max_key) {
                    if (
                        document.getElementsByName(value)[0].value != undefined
                    ) {
                        if (
                            parseInt(
                                document.getElementsByName(value)[0].value
                            ) != 0 &&
                            [
                                '',
                                'Select Make',
                                'Select Model',
                                'Select Year'
                            ].indexOf(
                                document.getElementsByName(value)[0].value
                            ) == -1
                        ) {
                            if (value == 'featurecategorynew') {
                                var getName = 'category_id';
                            }
                            if (value == 'featuremakenew') {
                                var getName = 'make';
                            }
                            if (value == 'featuremodelnew') {
                                var getName = 'model';
                            }

                            add_url += `&${
                                getName !== 'undefined' ? getName : value
                            }=${encodeURIComponent(
                                document.getElementsByName(value)[0].value
                            )}`;
                        }
                    }
                } else {
                    if (add_url.match(/category_id=7($|&)/)) {
                        add_url = add_url.replace(
                            '&category_id=7',
                            '&category_id=1'
                        );
                        _.setState({
                            isTaxi: 1
                        });
                    } else {
                        _.setState({
                            isTaxi: 0
                        });
                    }
                    if (value == 'featuremakenew') {
                        var getNameText = 'Make';
                    }
                    if (value == 'featuremodelnew') {
                        var getNameText = 'Model';
                    }
                    if (value == 'featuretrimnew') {
                        var getNameText = 'Trim';
                    }
                    // document.getElementsByName(value)[0].innerHTML="<option value=''>Select "+getNameText+"</option>";
                    document.getElementsByName(
                        value
                    )[0].innerHTML = `<option value=''>${_.props.translator.translate(
                        `Select_${getNameText}`
                    )}</option>`;
                }
            });
            if (document.getElementsByName('featuremodelnew')[0].value != '') {
                add_url += '&check_obv=1';
            }
            add_url += '&api_version=3';

            const request = new XMLHttpRequest();
            request.open(
                'GET',
                `${this.props.ENV_WEB_URL}/mmt_new?${add_url}`,
                true
            );
            request.onload = function () {
                if (this.status >= 200 && this.status < 400) {
                    const data = JSON.parse(this.response);
                    let options = '';

                    switch (element_name) {
                        case 'featurecategorynew':
                            element_name_next = 'featuremakenew';
                            _.setState({
                                excepted_kms_placeHolder:
                                    'Exp. Kms Driven/Year.'
                            });
                            _.setState({
                                excepted_kms_placeHolder: 'Exp. Kms Driven/Year'
                            });

                            if (element_value == 4) {
                                _.setState({
                                    kms_placeHolder: 'Enter Hrs Flown'
                                });
                            } else {
                                _.setState({
                                    kms_placeHolder: 'Enter KMs Driven'
                                });
                            }
                            if (element_value == 5) {
                                _.setState({
                                    kms_disabled: 'disabled'
                                });
                                _.validation.kms_driven = {};
                            } else {
                                _.setState({
                                    kms_disabled: ''
                                });
                                _.validation.kms_driven = {
                                    required: 1,
                                    min_val: 10,
                                    max_val: 500000,
                                    input_type: 'number'
                                };
                            }

                            break;
                        case 'featuremakenew':
                            element_name_next = 'featuremodelnew';
                            break;
                        // case "model":
                        //   element_name= "year";
                        //   break;
                        case 'featuremodelnew':
                            element_name_next = 'featuretrimnew';
                            _.setState({
                                trimStatus: data.data?.check_obv || {}
                            });
                            data.data = data.data.result;
                            break;
                        default:
                    }

                    element_name = element_value
                        ? element_name_next
                        : element_name;

                    const element_name_text = element_name.replace(
                        /(new|feature)/g,
                        ''
                    );
                    options += `<option value=''>${_.props.translator.translate(
                        `Select_${
                            element_name_text.charAt(0).toUpperCase() +
                            element_name_text.slice(1)
                        }`
                    )}</option>`;

                    if (element_name == 'featuremakenew') {
                        // console.log( data);
                        data.data?.length &&
                            data.data.map(function (value) {
                                // console.log( value);
                                if (value.selectable == false) {
                                    options += `<option disabled='disabled' value='${value.name}'> ${value.name}</option>`;
                                } else options += `<option value='${value.name}'> ${value.name}</option>`;
                            });
                    } else {
                        data.data?.length &&
                            data.data.map(function (value) {
                                options += `<option value='${value}'> ${value}</option>`;
                            });
                    }

                    document.getElementsByName(element_name)[0].innerHTML =
                        options;
                } else {
                    console.error('Response received and there was an error');
                }
            };

            request.onerror = function () {
                console.error('Request error');
            };
            request.send();
        }
    }

    handleChangeFeatureUsed(event) {
        const getKey = (obj, val) =>
            Object.keys(obj).find(key => obj[key] === val);
        const _ = this;
        let max_key;
        let add_url = '';
        let element_name_next;
        let element_name = event.target.name;
        let kms_elem;
        const element_value = event.target.value;

        const element_names = [
            'featurecategoryused',
            'featuremakeused',
            'featuremodelused',
            'featureyearused',
            'featuretrimused'
        ];
        if (element_names.indexOf(element_name) > -1) {
            max_key = getKey(element_names, element_name);

            element_names.forEach(function (value, key) {
                if (key <= max_key) {
                    if (
                        document.getElementsByName(value)[0].value != undefined
                    ) {
                        if (
                            parseInt(
                                document.getElementsByName(value)[0].value
                            ) != 0 &&
                            [
                                '',
                                'Select Make',
                                'Select Model',
                                'Select Year'
                            ].indexOf(
                                document.getElementsByName(value)[0].value
                            ) == -1
                        ) {
                            if (value == 'featurecategoryused') {
                                var getName = 'category_id';
                            }
                            if (value == 'featuremakeused') {
                                var getName = 'make';
                            }
                            if (value == 'featuremodelused') {
                                var getName = 'model';
                            }
                            if (value == 'featureyearused') {
                                var getName = 'year';
                            }
                            add_url += `&${
                                getName !== 'undefined' ? getName : value
                            }=${encodeURIComponent(
                                document.getElementsByName(value)[0].value
                            )}`;
                        }
                    }
                } else {
                    if (add_url.match(/category_id=7($|&)/)) {
                        add_url = add_url.replace(
                            '&category_id=7',
                            '&category_id=1'
                        );
                        _.setState({
                            isTaxi: 1
                        });
                    } else {
                        _.setState({
                            isTaxi: 0
                        });
                    }

                    if (value == 'featuremakeused') {
                        var getNameText = 'Make';
                    }
                    if (value == 'featuremodelused') {
                        var getNameText = 'Model';
                    }
                    if (value == 'featureyearused') {
                        var getNameText = 'Year';
                    }
                    if (value == 'featuretrimused') {
                        var getNameText = 'Trim';
                    }

                    document.getElementsByName(
                        value
                    )[0].innerHTML = `<option value=''>${_.props.translator.translate(
                        `Select_${getNameText}`
                    )}</option>`;
                }
            });
            if (document.getElementsByName('featureyearused')[0].value != '') {
                add_url += '&check_obv=1';
            }
            add_url += '&api_version=3';
            const request = new XMLHttpRequest();
            request.open(
                'GET',
                `//${window.location.hostname}/mmyt?${add_url}`,
                true
            );
            // request.open('GET', this.props.ENV_WEB_URL+'/mmyt?'+ add_url, true);
            request.onload = function () {
                if (this.status >= 200 && this.status < 400) {
                    const data = JSON.parse(this.response);
                    let options = '';

                    switch (element_name) {
                        case 'featurecategoryused':
                            element_name_next = 'featuremakeused';
                            _.setState({
                                excepted_kms_placeHolder:
                                    'Exp. Kms Driven/Year.'
                            });
                            _.setState({
                                excepted_kms_placeHolder: 'Exp. Kms Driven/Year'
                            });

                            _.setState({
                                kms_placeHolder: 'Enter KMs Driven'
                            });
                            _.setState({
                                kms_placeHolder: 'Enter KMs Driven'
                            });

                            if (element_value == 4) {
                                _.setState({
                                    kms_placeHolder: 'Enter Hrs Flown'
                                });
                            } else {
                                _.setState({
                                    kms_placeHolder: 'Enter KMs Driven'
                                });
                            }
                            if (element_value == 5) {
                                _.setState({
                                    kms_disabled: 'disabled'
                                });
                                _.validation.kms_driven = {};
                            } else {
                                _.setState({
                                    kms_disabled: ''
                                });
                                _.validation.kms_driven = {
                                    required: 1,
                                    min_val: 10,
                                    max_val: 500000,
                                    input_type: 'number'
                                };
                            }

                            break;
                        case 'featuremakeused':
                            element_name_next = 'featuremodelused';
                            break;
                        case 'featuremodelused':
                            element_name_next = 'featureyearused';
                            break;
                        case 'featureyearused':
                            element_name_next = 'featuretrimused';
                            _.setState({
                                trimStatus: data.data?.check_obv || {}
                            });
                            data.data = data.data.result;
                            break;
                        default:
                    }

                    element_name = element_value
                        ? element_name_next
                        : element_name;

                    const element_name_text = element_name.replace(
                        /(used|feature)/g,
                        ''
                    );
                    options += `<option value=''>${_.props.translator.translate(
                        `Select_${
                            element_name_text.charAt(0).toUpperCase() +
                            element_name_text.slice(1)
                        }`
                    )}</option>`;

                    if (element_name == 'featuremakeused') {
                        // console.log( data);
                        data.data?.length &&
                            data.data.map(function (value) {
                                // console.log( value);
                                if (value.selectable == false) {
                                    options += `<option disabled='disabled' value='${value.name}'> ${value.name}</option>`;
                                } else options += `<option value='${value.name}'> ${value.name}</option>`;
                            });
                    } else {
                        data.data?.length &&
                            data.data.map(function (value) {
                                options += `<option value='${value}'> ${value}</option>`;
                            });
                    }

                    document.getElementsByName(element_name)[0].innerHTML =
                        options;
                } else {
                    console.error('Response received and there was an error');
                }
            };

            request.onerror = function () {
                console.error('Request error');
            };
            request.send();
        }
    }

    handleChangeLeaseNew(event) {
        const getKey = (obj, val) =>
            Object.keys(obj).find(key => obj[key] === val);
        const _ = this;
        let max_key;
        let add_url = '';
        let element_name_next;
        let element_name = event.target.name;
        let kms_elem;
        const element_value = event.target.value;

        const element_names = [
            'leasecategorynew',
            'leasemakenew',
            'leasemodelnew',
            'leasetrimnew'
        ];
        if (element_names.indexOf(element_name) > -1) {
            max_key = getKey(element_names, element_name);

            element_names.forEach(function (value, key) {
                if (key <= max_key) {
                    if (
                        document.getElementsByName(value)[0].value != undefined
                    ) {
                        if (
                            parseInt(
                                document.getElementsByName(value)[0].value
                            ) != 0 &&
                            [
                                '',
                                'Select Make',
                                'Select Model',
                                'Select Year'
                            ].indexOf(
                                document.getElementsByName(value)[0].value
                            ) == -1
                        ) {
                            if (value == 'leasecategorynew') {
                                var getName = 'category_id';
                            }
                            if (value == 'leasemakenew') {
                                var getName = 'make';
                            }
                            if (value == 'leasemodelnew') {
                                var getName = 'model';
                            }

                            add_url += `&${
                                getName !== 'undefined' ? getName : value
                            }=${encodeURIComponent(
                                document.getElementsByName(value)[0].value
                            )}`;
                        }
                    }
                } else {
                    if (add_url.match(/category_id=7($|&)/)) {
                        add_url = add_url.replace(
                            '&category_id=7',
                            '&category_id=1'
                        );
                        _.setState({
                            isTaxi: 1
                        });
                    } else {
                        _.setState({
                            isTaxi: 0
                        });
                    }

                    if (value == 'leasemakenew') {
                        var getNameText = 'Make';
                    }
                    if (value == 'leasemodelnew') {
                        var getNameText = 'Model';
                    }
                    if (value == 'leasetrimnew') {
                        var getNameText = 'Trim';
                    }
                    document.getElementsByName(
                        value
                    )[0].innerHTML = `<option value=''>${_.props.translator.translate(
                        `Select_${getNameText}`
                    )}</option>`;
                }
            });

            if (document.getElementsByName('leasemodelnew')[0].value != '') {
                add_url += '&check_obv=1';
            }
            add_url += '&api_version=3';
            // alert(add_url);
            const request = new XMLHttpRequest();
            request.open(
                'GET',
                `${this.props.ENV_WEB_URL}/mmt_new?${add_url}`,
                true
            );
            request.onload = function () {
                if (this.status >= 200 && this.status < 400) {
                    const data = JSON.parse(this.response);
                    let options = '';

                    switch (element_name) {
                        case 'leasecategorynew':
                            element_name_next = 'leasemakenew';

                            if (element_value == 4) {
                                _.setState({
                                    kms_placeHolder: 'Enter Hrs Flown'
                                });
                            } else {
                                _.setState({
                                    kms_placeHolder: 'Enter KMs Driven'
                                });
                            }
                            if (element_value == 5) {
                                _.setState({
                                    kms_disabled: 'disabled'
                                });
                                _.validation.kms_driven = {};
                            } else {
                                _.setState({
                                    kms_disabled: ''
                                });
                                _.validation.kms_driven = {
                                    required: 1,
                                    min_val: 10,
                                    max_val: 500000,
                                    input_type: 'number'
                                };
                            }

                            break;
                        case 'leasemakenew':
                            element_name_next = 'leasemodelnew';
                            break;
                        // case "model":
                        //   element_name= "year";
                        //   break;
                        case 'leasemodelnew':
                            element_name_next = 'leasetrimnew';
                            _.setState({
                                trimStatus: data.data?.check_obv || {}
                            });
                            data.data = data.data.result;
                            break;
                        default:
                    }

                    element_name = element_value
                        ? element_name_next
                        : element_name;

                    const element_name_text = element_name.replace(
                        /(new|lease)/g,
                        ''
                    );
                    options += `<option value=''>${_.props.translator.translate(
                        `Select_${
                            element_name_text.charAt(0).toUpperCase() +
                            element_name_text.slice(1)
                        }`
                    )}</option>`;

                    if (element_name == 'leasemakenew') {
                        data.data?.length &&
                            data.data.map(function (value) {
                                if (value.selectable == false) {
                                    options += `<option disabled='disabled' value='${value.name}'> ${value.name}</option>`;
                                } else options += `<option value='${value.name}'> ${value.name}</option>`;
                            });
                    } else {
                        data.data?.length &&
                            data.data.map(function (value) {
                                options += `<option value='${value}'> ${value}</option>`;
                            });
                    }

                    document.getElementsByName(element_name)[0].innerHTML =
                        options;
                } else {
                    console.error('Response received and there was an error');
                }
            };

            request.onerror = function () {
                console.error('Request error');
            };
            request.send();
        }
    }

    handleChangeLease(event) {
        const getKey = (obj, val) =>
            Object.keys(obj).find(key => obj[key] === val);
        const _ = this;
        let max_key;
        let add_url = '';
        let element_name_next;
        let element_name = event.target.name;
        let kms_elem;
        const element_value = event.target.value;

        const element_names = [
            'leasecategory',
            'leasemake',
            'leasemodel',
            'leaseyear',
            'leasetrim'
        ];
        if (element_names.indexOf(element_name) > -1) {
            max_key = getKey(element_names, element_name);

            element_names.forEach(function (value, key) {
                if (key <= max_key) {
                    if (
                        document.getElementsByName(value)[0].value != undefined
                    ) {
                        if (
                            parseInt(
                                document.getElementsByName(value)[0].value
                            ) != 0 &&
                            [
                                '',
                                'Select Make',
                                'Select Model',
                                'Select Year'
                            ].indexOf(
                                document.getElementsByName(value)[0].value
                            ) == -1
                        ) {
                            if (value == 'leasecategory') {
                                var getName = 'category_id';
                            }
                            if (value == 'leasemake') {
                                var getName = 'make';
                            }
                            if (value == 'leasemodel') {
                                var getName = 'model';
                            }
                            if (value == 'leaseyear') {
                                var getName = 'year';
                            }
                            add_url += `&${
                                getName !== 'undefined' ? getName : value
                            }=${encodeURIComponent(
                                document.getElementsByName(value)[0].value
                            )}`;
                        }
                    }
                } else {
                    if (add_url.match(/category_id=7($|&)/)) {
                        add_url = add_url.replace(
                            '&category_id=7',
                            '&category_id=1'
                        );
                        _.setState({
                            isTaxi: 1
                        });
                    } else {
                        _.setState({
                            isTaxi: 0
                        });
                    }

                    if (value == 'leasemake') {
                        var getNameText = 'Make';
                    }
                    if (value == 'leasemodel') {
                        var getNameText = 'Model';
                    }
                    if (value == 'leaseyear') {
                        var getNameText = 'Year';
                    }
                    if (value == 'leasetrim') {
                        var getNameText = 'Trim';
                    }
                    document.getElementsByName(
                        value
                    )[0].innerHTML = `<option value=''>${_.props.translator.translate(
                        `Select_${getNameText}`
                    )}</option>`;
                }
            });
            if (document.getElementsByName('leaseyear')[0].value != '') {
                add_url += '&check_obv=1';
            }
            add_url += '&api_version=3';
            const request = new XMLHttpRequest();
            // request.open('GET', this.props.ENV_WEB_URL+'/mmyt?'+ add_url, true);
            request.open(
                'GET',
                `//${window.location.hostname}/mmyt?${add_url}`,
                true
            );
            request.onload = function () {
                if (this.status >= 200 && this.status < 400) {
                    const data = JSON.parse(this.response);
                    let options = '';

                    switch (element_name) {
                        case 'leasecategory':
                            element_name_next = 'leasemake';

                            if (element_value == 4) {
                                _.setState({
                                    kms_placeHolder: 'Enter Hrs Flown'
                                });
                            } else {
                                _.setState({
                                    kms_placeHolder: 'Enter KMs Driven'
                                });
                            }
                            if (element_value == 5) {
                                _.setState({
                                    kms_disabled: 'disabled'
                                });
                                _.validation.kms_driven = {};
                            } else {
                                _.setState({
                                    kms_disabled: ''
                                });
                                _.validation.kms_driven = {
                                    required: 1,
                                    min_val: 10,
                                    max_val: 500000,
                                    input_type: 'number'
                                };
                            }

                            break;
                        case 'leasemake':
                            element_name_next = 'leasemodel';
                            break;
                        case 'leasemodel':
                            element_name_next = 'leaseyear';
                            break;
                        case 'leaseyear':
                            element_name_next = 'leasetrim';
                            _.setState({
                                trimStatus: data.data?.check_obv || {}
                            });
                            data.data = data.data.result;
                            break;
                        default:
                    }

                    element_name = element_value
                        ? element_name_next
                        : element_name;

                    const element_name_text = element_name.replace(
                        /(lease)/g,
                        ''
                    );
                    options += `<option value=''>${_.props.translator.translate(
                        `Select_${
                            element_name_text.charAt(0).toUpperCase() +
                            element_name_text.slice(1)
                        }`
                    )}</option>`;

                    if (element_name == 'leasemake') {
                        // console.log( data);
                        data.data?.length &&
                            data.data.map(function (value) {
                                // console.log( value);
                                if (value.selectable == false) {
                                    options += `<option disabled='disabled' value='${value.name}'> ${value.name}</option>`;
                                } else options += `<option value='${value.name}'> ${value.name}</option>`;
                            });
                    } else {
                        data.data?.length &&
                            data.data.map(function (value) {
                                options += `<option value='${value}'> ${value}</option>`;
                            });
                    }

                    document.getElementsByName(element_name)[0].innerHTML =
                        options;
                } else {
                    console.error('Response received and there was an error');
                }
            };

            request.onerror = function () {
                console.error('Request error');
            };
            request.send();
        }
    }

    handleChangeExchange(event) {
        const getKey = (obj, val) =>
            Object.keys(obj).find(key => obj[key] === val);
        const _ = this;
        let max_key;
        const element_name_e = '';
        let element_name_u = '';
        let add_url = '';
        let element_name_next;
        let element_name = event.target.name;
        let kms_elem;
        const element_value = event.target.value;

        const element_names = [
            'exchangecategory',
            'exchangemake',
            'exchangemodel',
            'exchangeyear',
            'exchangetrim'
        ];
        if (element_names.indexOf(element_name) > -1) {
            max_key = getKey(element_names, element_name);

            element_names.forEach(function (value, key) {
                if (key <= max_key) {
                    if (
                        document.getElementsByName(value)[0].value != undefined
                    ) {
                        if (
                            parseInt(
                                document.getElementsByName(value)[0].value
                            ) != 0 &&
                            [
                                '',
                                'Select Make',
                                'Select Model',
                                'Select Year',
                                'Select Trim'
                            ].indexOf(
                                document.getElementsByName(value)[0].value
                            ) == -1
                        ) {
                            if (value == 'exchangecategory') {
                                var getName = 'category_id';
                            }
                            if (value == 'exchangemake') {
                                var getName = 'make';
                            }
                            if (value == 'exchangemodel') {
                                var getName = 'model';
                            }

                            if (value == 'exchangeyear') {
                                var getName = 'year';
                            }
                            add_url += `&${
                                getName !== 'undefined' ? getName : value
                            }=${encodeURIComponent(
                                document.getElementsByName(value)[0].value
                            )}`;
                        }
                    }
                } else {
                    if (add_url.match(/category_id=7($|&)/)) {
                        add_url = add_url.replace(
                            '&category_id=7',
                            '&category_id=1'
                        );
                    }

                    if (value == 'exchangemake') {
                        var getNameText = 'Make';
                    }
                    if (value == 'exchangemodel') {
                        var getNameText = 'Model';
                    }

                    if (value == 'exchangeyear') {
                        var getNameText = 'Year';
                    }
                    if (value == 'exchangetrim') {
                        var getNameText = 'Trim';
                    }
                    document.getElementsByName(
                        value
                    )[0].innerHTML = `<option value=''>${_.props.translator.translate(
                        `Select_${getNameText}`
                    )}</option>`;
                }
            });
            if (document.getElementsByName('exchangeyear')[0].value != '') {
                add_url += '&check_obv=1';
            }
            add_url += '&api_version=3';

            const request = new XMLHttpRequest();
            // request.open('GET', '//'+window.location.hostname+'/mmyt?'+add_url, true);
            request.open(
                'GET',
                `${this.props.ENV_WEB_URL}/mmyt?${add_url}`,
                true
            );
            request.onload = function () {
                if (this.status >= 200 && this.status < 400) {
                    const data = JSON.parse(this.response);
                    let options = '';

                    switch (element_name) {
                        case 'exchangecategory':
                            element_name_next = 'exchangemake';
                            element_name_u = 'exchangemakeused';

                            break;
                        case 'exchangemake':
                            element_name_next = 'exchangemodel';
                            break;
                        case 'exchangemodel':
                            element_name_next = 'exchangeyear';
                            break;
                        case 'exchangeyear':
                            element_name_next = 'exchangetrim';

                            data.data = data.data.result;
                            break;
                        default:
                    }

                    let options1;

                    element_name = element_value
                        ? element_name_next
                        : element_name;

                    const element_name_text = element_name.replace(
                        /(new|exchange)/g,
                        ''
                    );
                    options += `<option value=''>${_.props.translator.translate(
                        `Select_${
                            element_name_text.charAt(0).toUpperCase() +
                            element_name_text.slice(1)
                        }`
                    )}</option>`;

                    options1 += `<option value=''>${_.props.translator.translate(
                        'Select_Make'
                    )}</option>`;

                    if (element_name == 'exchangemake') {
                        data.data?.length &&
                            data.data.map(function (value) {
                                if (value.selectable == false) {
                                    options += `<option disabled='disabled' value='${value.name}'> ${value.name}</option>`;
                                    options1 += `<option disabled='disabled' value='${value.name}'> ${value.name}</option>`;
                                } else {
                                    options += `<option value='${value.name}'> ${value.name}</option>`;
                                    options1 += `<option value='${value.name}'> ${value.name}</option>`;
                                }
                            });
                    } else {
                        data.data?.length &&
                            data.data.map(function (value) {
                                options += `<option value='${value}'> ${value}</option>`;
                            });
                    }

                    document.getElementsByName(element_name)[0].innerHTML =
                        options;
                    // document.getElementsByName(element_name_e)[0].innerHTML= options1;
                    if (document.getElementsByName(element_name_u)?.[0]) {
                        document.getElementsByName(
                            element_name_u
                        )[0].innerHTML = options1;
                    }
                } else {
                    console.error('Response received and there was an error');
                }
            };

            request.onerror = function () {
                console.error('Request error');
            };
            request.send();
        }
    }

    handleChange(event) {
        const getKey = (obj, val) =>
            Object.keys(obj).find(key => obj[key] === val);
        const _ = this;
        let max_key;
        let add_url = '';
        let element_name_next;
        let element_name = event.target.name;
        let kms_elem;
        const element_value = event.target.value;
        const element_names = ['category', 'make', 'model', 'year', 'trim'];

        if (element_names.indexOf(element_name) > -1) {
            max_key = getKey(element_names, element_name);
            element_names.forEach(function (value, key) {
                if (key <= max_key) {
                    if (
                        document.getElementsByName(value)[0].value != undefined
                    ) {
                        if (
                            parseInt(
                                document.getElementsByName(value)[0].value
                            ) != 0 &&
                            [
                                '',
                                'Select Make',
                                'Select Model',
                                'Select Year'
                            ].indexOf(
                                document.getElementsByName(value)[0].value
                            ) == -1
                        ) {
                            add_url += `&${
                                value == 'category' ? 'category_id' : value
                            }=${encodeURIComponent(
                                document.getElementsByName(value)[0].value
                            )}`;
                        }
                    }
                } else {
                    if (add_url.match(/category_id=7($|&)/)) {
                        add_url = add_url.replace(
                            '&category_id=7',
                            '&category_id=1'
                        );
                        _.setState({
                            isTaxi: 1
                        });
                    } else {
                        _.setState({
                            isTaxi: 0
                        });
                    }
                    document.getElementsByName('kms_driven').value = '';
                    const select = `Select_${value
                        .charAt(0)
                        .toUpperCase()}${value.slice(1)}`;
                    document.getElementsByName(
                        value
                    )[0].innerHTML = `<option value=''>${_.props.translator.translate(
                        select
                    )}</option>`;
                }
            });
            if (document.getElementsByName('year')[0].value != '') {
                add_url += '&check_obv=1';
            }
            add_url += '&api_version=3';
            const request = new XMLHttpRequest();

            // request.open('GET', '//'+window.location.hostname+'/mmyt?'+add_url, true);
            request.open(
                'GET',
                `${this.props.ENV_WEB_URL}/mmyt?${add_url}`,
                true
            );
            const element_name_kms = '';
            request.onload = function () {
                if (this.status >= 200 && this.status < 400) {
                    const data = JSON.parse(this.response);
                    let options = '';
                    switch (element_name) {
                        case 'category':
                            element_name_next = 'make';
                            _.setState({
                                kms_placeHolder: 'Enter KMs Driven'
                            });
                            // element_name_kms= "kms_driven";

                            if (element_value == 4) {
                                _.setState({
                                    kms_placeHolder: 'Enter Hrs Flown'
                                });
                            } else {
                                _.setState({
                                    kms_placeHolder: 'Enter KMs Driven'
                                });
                            }
                            if (element_value == 5) {
                                _.setState({
                                    kms_disabled: 'disabled'
                                });
                                _.validation.kms_driven = {};
                            } else {
                                _.setState({
                                    kms_disabled: ''
                                });
                                _.validation.kms_driven = {
                                    required: 1,
                                    min_val: 10,
                                    max_val: 500000,
                                    input_type: 'number'
                                };
                            }

                            break;
                        case 'make':
                            element_name_next = 'model';
                            break;
                        case 'model':
                            element_name_next = 'year';
                            break;
                        case 'year':
                            element_name_next = 'trim';
                            _.setState({
                                trimStatus: data.data?.check_obv || {}
                            });
                            data.data = data.data.result;
                            break;
                        default:
                    }

                    element_name = element_value
                        ? element_name_next
                        : element_name;

                    options += `<option value=''>${_.props.translator.translate(
                        `Select_${element_name
                            .charAt(0)
                            .toUpperCase()}${element_name.slice(1)}`
                    )}</option>`;
                    if (element_name == 'make') {
                        // console.log( data);
                        data.data?.length &&
                            data.data.map(function (value) {
                                // console.log( value);
                                if (value.selectable == false) {
                                    options += `<option disabled='disabled' value='${value.name}'> ${value.name}</option>`;
                                } else options += `<option value='${value.name}'> ${value.name}</option>`;
                            });
                    } else {
                        data.data?.length &&
                            data.data.map(function (value) {
                                options += `<option value='${value}'> ${value}</option>`;
                            });
                    }
                    // console.log(element_name);
                    document.getElementsByName(element_name)[0].innerHTML =
                        options;
                } else {
                    console.error('Response received and there was an error');
                }
            };

            request.onerror = function () {
                console.error('Request error');
            };
            request.send();
        }
    }

    handleTabChange(event) {
        this.setState({
            formErrors: {}
        });

        const className = ' active ';
        if (
            event.target.innerText == 'Sell' ||
            event.target.innerText == 'बेचना'
        ) {
            this.setState({ transaction_type: 's' });
            document.getElementById('transaction_type').value = 's';
            this.setState({
                buyText: 'To'
            });
        } else {
            this.setState({ transaction_type: 'b' });
            document.getElementById('transaction_type').value = 'b';
            this.setState({
                buyText: 'From'
            });
        }
    }

    handleTabChangePrice(event) {
        this.setState({
            formErrors: {}
        });

        const className = ' active ';
        if (
            ` ${
                document.querySelector('a[aria-controls="pills-sell-price"]')
                    .className
            } `
                .replace(/[\n\t]/g, ' ')
                .indexOf(' active ') > -1
        ) {
            this.setState({ transaction_type: 's' });
            document.getElementById('transaction_type_used').value = 's';
            this.setState({
                buyText: 'To'
            });
        } else {
            this.setState({ transaction_type: 'b' });
            document.getElementById('transaction_type_used').value = 'b';
            this.setState({
                buyText: 'From'
            });
        }
    }

    handleExchangeToggle(event) {
        let selected_exchange_type = '';
        document
            .getElementById('exchangeForm')
            .exchange_type.forEach(function (val) {
                if (val.checked) {
                    selected_exchange_type = val.value;
                }
            });
        if (selected_exchange_type == 'exchange_with_new') {
            this.setState({
                exchange_type: false
            });
        } else {
            this.setState({
                exchange_type: true
            });
        }
        this.resetSearchVehicleHandler();
    }

    searchVehicleHandler() {
        this.setState({
            searchVehicle: !this.state.searchVehicle
        });
    }

    resetSearchVehicleHandler() {
        this.setState({
            searchVehicle: false
        });
    }

    componentDidMount() {
        document.getElementById('checkDeal').setAttribute('checked', 'checked');
        // document.getElementById("newExchange").setAttribute("checked", "checked");
        const _ = this;

        let getUrl = window.location.pathname;

        getUrl = getUnLocalizedPath(getUrl);

        getUrl = getUrl.split('/');

        const currentURL = getUrl[1] ? getUrl[1] : 'used';

        const tab =
            currentURL === 'future'
                ? 'Future Price'
                : `${currentURL.charAt(0).toUpperCase()}${currentURL.slice(1)}`;

        const tabExists = document.querySelector(
            `li a.nav-link[data-tab="${tab}"]`
        );

        this.setState({
            currentTab: tabExists ? tab : 'Used'
        });

        if (sessionStorage.getItem('otp_varified')) {
            const getOtpvarified = JSON.parse(
                sessionStorage.getItem('otp_varified')
            );
            this.setState({
                getPhone: 111111111 // getOtpvarified
            });
        }
    }

    // handleSetOtp()
    // {
    //   this.setState({
    //     getotp:'show',
    //     getForm:'newForm',
    //   })
    //   console.log(this.state.getotp+' w0se');
    // }

    render() {
        return (
            <div className="block m-0">
                {this.state.getPhone == undefined ? (
                    <OtpModal
                        gotp={this.state.getotp}
                        frm={this.state.getForm}
                    />
                ) : (
                    ''
                )}
                {/* <div id="modelerrors" className="modal" tabIndex="-1" role="dialog" style={divStyle}>
							<div className="modal-dialog" role="document">
									<div className="modal-content">
											<div className="modal-header">
													<h5 className="modal-title" id="myModalLabel"><i className="fa fa-info-circle text-info" aria-hidden="true"></i> Info</h5>
											</div>
											<div className="modal-body _14p thin">
                                            <div className="form-group">
                                           <label for="email">Email address:</label>
                                            <input className="form-control" type="number" name ="otp_mobile" id ="otp_mobile"  min ="0" oninput ="this.value = Math.abs(this.value)"/>
                                           </div>
											</div>
											<div className="modal-footer">
													<button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.removeModal}>Close</button>
											</div>
									</div>
							</div>
					</div> */}

                <div className="header">
                    <h6 className="heading text-center">
                        {/* Check Fair Market Value For Any Vehicle */}
                        <Text
                            id="check_Fair_Market_Value_For_Any_Vehicle"
                            defaultMessage="Used Vehicle Pricing Calculator"
                        />
                    </h6>
                </div>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a
                            className={`nav-link ${
                                this.state.currentTab == 'Used' ? 'active' : ''
                            } `}
                            id="used-tab"
                            data-toggle="tab"
                            href="#used"
                            role="tab"
                            onClick={this.handleChangeTab}
                            aria-controls="used"
                            data-tab="Used"
                            aria-selected="true"
                        >
                            <Text id="Used" defaultMessage="Used" />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${
                                this.state.currentTab == 'New' ? 'active' : ''
                            } `}
                            id="new-tab"
                            data-toggle="tab"
                            onClick={this.handleChangeTab}
                            href="#new"
                            role="tab"
                            aria-controls="new"
                            data-tab="New"
                            aria-selected="false"
                        >
                            <Text id="New" defaultMessage="New" />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${
                                this.state.currentTab == 'Exchange'
                                    ? 'active'
                                    : ''
                            } `}
                            id="exchange-tab"
                            data-toggle="tab"
                            href="#exchange"
                            role="tab"
                            onClick={this.handleChangeTab}
                            aria-controls="exchange"
                            data-tab="Exchange"
                            aria-selected="false"
                        >
                            <Text id="Exchange" defaultMessage="Exchange" />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${
                                this.state.currentTab == 'Future Price'
                                    ? 'active'
                                    : ''
                            } `}
                            id="price-tab"
                            data-toggle="tab"
                            href="#price"
                            role="tab"
                            onClick={this.handleChangeTab}
                            aria-controls="price"
                            data-tab="Future Price"
                            aria-selected="false"
                        >
                            <Text
                                id="Future_Price"
                                defaultMessage="Future Price"
                            />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${
                                this.state.currentTab == 'Residual'
                                    ? 'active'
                                    : ''
                            } `}
                            id="lease-tab"
                            data-toggle="tab"
                            href="#lease"
                            role="tab"
                            onClick={this.handleChangeTab}
                            aria-controls="lease"
                            data-tab="Residual"
                            aria-selected="false"
                        >
                            <Text id="Residual" defaultMessage="Residual" />
                        </a>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div
                        className={`tab-pane fade show ${
                            this.state.currentTab == 'Used' ? 'active' : ''
                        } `}
                        id="used"
                        role="tabpanel"
                        aria-labelledby="used-tab"
                    >
                        <div className="content p-0">
                            <p>
                                <Text
                                    id="Used_Vehicle_Pricing_Calculator_shows_the_pricing_of_Used_Automobiles"
                                    defaultMessage="Used Vehicle Pricing Calculator shows the pricing of Used Automobiles"
                                />
                            </p>
                            <ul
                                className="nav nav-pills nav-fill mb-3"
                                role="tablist"
                            >
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        onClick={this.handleTabChange}
                                        data-toggle="pill"
                                        href="#pills-sell"
                                        role="tab"
                                        aria-controls="pills-sell"
                                        aria-selected="true"
                                    >
                                        <Text id="Sell" defaultMessage="Sell" />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        onClick={this.handleTabChange}
                                        data-toggle="pill"
                                        href="#pills-buy"
                                        role="tab"
                                        aria-controls="pills-buy"
                                        aria-selected="false"
                                    >
                                        <Text id="Buy" defaultMessage="Buy" />
                                    </a>
                                </li>
                            </ul>

                            <form
                                action={`${this.state.postResult}`}
                                id="vehicleForm"
                                onSubmit={this.handleSubmit}
                                method="POST"
                            >
                                <input
                                    type="hidden"
                                    value="used"
                                    name="feature"
                                />
                                <ul className="user-category text-center obv-padding-left-0">
                                    <li className="obv-display-inline-block">
                                        <div className="form-group without-arrow">
                                            <div className="custom-control custome-controls-success custom-radio">
                                                <input
                                                    id="checkIndi"
                                                    type="radio"
                                                    className="custom-control-input"
                                                    name="customer_type"
                                                    value="individual"
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="checkIndi"
                                                >
                                                    {this.props.translator.translate(
                                                        `${this.state.buyText}_Individual`
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="obv-display-inline-block obv-margin-left-30">
                                        <div className="form-group without-arrow">
                                            <div className="custom-control custom-radio">
                                                <input
                                                    id="checkDeal"
                                                    type="radio"
                                                    className="custom-control-input"
                                                    name="customer_type"
                                                    value="dealer"
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="checkDeal"
                                                >
                                                    {this.props.translator.translate(
                                                        `${this.state.buyText}_Dealer`
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                {this.state.searchVehicle == true ? (
                                    <div className="row">
                                        <div className="col">
                                            <VehicleSearch
                                                clickSearchVehicle={
                                                    this.searchVehicleHandler
                                                }
                                                iskmsdriven="Yes"
                                                feature="used"
                                                car_condition="used"
                                                isCondition="No"
                                            />

                                            {/* <VehicleSearch/> */}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <Select
                                                        updateValidation={this._updateValidation.bind(
                                                            this
                                                        )}
                                                        className="form-control"
                                                        name="category"
                                                        values={
                                                            this.state
                                                                .categories
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        label={this.props.translator.translate(
                                                            'Select_Category'
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <Select
                                                        updateValidation={this._updateValidation.bind(
                                                            this
                                                        )}
                                                        className="form-control"
                                                        name="make"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        label={this.props.translator.translate(
                                                            'Select_Make'
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <Select
                                                        updateValidation={this._updateValidation.bind(
                                                            this
                                                        )}
                                                        className="form-control"
                                                        name="model"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        label={this.props.translator.translate(
                                                            'Select_Model'
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <Select
                                                        updateValidation={this._updateValidation.bind(
                                                            this
                                                        )}
                                                        className="form-control"
                                                        name="year"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        label={this.props.translator.translate(
                                                            'Select_Year'
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <Select
                                                        updateValidation={this._updateValidation.bind(
                                                            this
                                                        )}
                                                        className="form-control"
                                                        name="trim"
                                                        label={this.props.translator.translate(
                                                            'Select_Trim'
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group without-arrow">
                                                    <TextBox
                                                        disabled={
                                                            this.state
                                                                .kms_disabled
                                                        }
                                                        updateValidation={this._updateValidation.bind(
                                                            this
                                                        )}
                                                        className="form-control"
                                                        name="kms_driven"
                                                        type="number"
                                                        placeHolder={this.props.translator.translate(
                                                            this.state
                                                                .kms_placeHolder
                                                        )}
                                                        onChange={
                                                            this
                                                                .handleInputChange
                                                        }
                                                    />
                                                    <input
                                                        type="hidden"
                                                        name="city"
                                                        id="usedCity"
                                                        value={
                                                            this.props
                                                                .locationCity
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.searchVehicle == true ? (
                                    <a
                                        href="#"
                                        role="button"
                                        className="link"
                                        onClick={this.searchVehicleHandler}
                                    >
                                        {this.props.translator.translate(
                                            'Or_Enter_Manually'
                                        )}
                                    </a>
                                ) : (
                                    <div className="row">
                                        <div className="col">
                                            <div className="">
                                                <a
                                                    href="#"
                                                    role="button"
                                                    className="link"
                                                    onClick={
                                                        this
                                                            .searchVehicleHandler
                                                    }
                                                >
                                                    {this.props.translator.translate(
                                                        'Or_Select_A_Vehicle'
                                                    )}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-12">
                                        <FormErrors
                                            formErrors={this.state.formErrors}
                                        />
                                        <div className="actions">
                                            <input
                                                type="hidden"
                                                name="phone"
                                                id="vehicleForm_phone"
                                                value={
                                                    this.state.getPhone !==
                                                    undefined
                                                        ? this.state.getPhone
                                                        : ''
                                                }
                                            />
                                            <input
                                                type="hidden"
                                                name="userType"
                                                id="vehicleForm_userType"
                                                value={
                                                    this.state.userType !==
                                                    undefined
                                                        ? this.state.userType
                                                        : ''
                                                }
                                            />
                                            <button
                                                className="btn btn-block btn-primary"
                                                id="check_price_used"
                                                type="button"
                                                onClick={this.handleSubmit}
                                            >
                                                {this.props.translator.translate(
                                                    'Check_Price'
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <input
                                    type="hidden"
                                    id="transaction_type"
                                    name="transaction_type"
                                    value={this.state.transaction_type}
                                />
                                <input
                                    type="hidden"
                                    id="is_taxi"
                                    name="is_taxi"
                                    value={this.state.isTaxi}
                                />
                                {/* <input type="hidden" name="_token" value={window.csrf_token} /> */}
                            </form>
                        </div>
                    </div>

                    <div
                        className={`tab-pane fade  ${
                            this.state.currentTab == 'New' ? 'active show' : ''
                        } `}
                        id="new"
                        role="tabpanel"
                        aria-labelledby="new-tab"
                    >
                        {this.state.currentTab == 'New' ? (
                            <div className="content p-0">
                                <p>
                                    <Text
                                        id="Check_Price_of_New_Vehicle_and_How_it_will_depreciate_in_Upcoming_Years"
                                        defaultMessage="Check Price of New Vehicle and How it will depreciate in Upcoming Years"
                                    />
                                </p>
                                <form
                                    action={this.state.postResult}
                                    id="newForm"
                                    onSubmit={this.handleSubmitNew}
                                    method="POST"
                                >
                                    <input
                                        type="hidden"
                                        value="new"
                                        name="feature"
                                    />
                                    {this.state.searchVehicle == true ? (
                                        <div className="row">
                                            <div className="col">
                                                <VehicleSearch
                                                    clickSearchVehicle={
                                                        this
                                                            .searchVehicleHandler
                                                    }
                                                    iskmsdriven="No"
                                                    feature="new"
                                                    car_condition="new"
                                                    isCondition="No"
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <Select
                                                            className="form-control"
                                                            name="newcategory"
                                                            values={
                                                                this.state
                                                                    .categories
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChangeNew
                                                            }
                                                            label={this.props.translator.translate(
                                                                'Select_Category'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <Select
                                                            className="form-control"
                                                            name="newmake"
                                                            onChange={
                                                                this
                                                                    .handleChangeNew
                                                            }
                                                            label={this.props.translator.translate(
                                                                'Select_Make'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <Select
                                                            className="form-control"
                                                            name="newmodel"
                                                            onChange={
                                                                this
                                                                    .handleChangeNew
                                                            }
                                                            label={this.props.translator.translate(
                                                                'Select_Model'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <Select
                                                            className="form-control"
                                                            name="newtrim"
                                                            label={this.props.translator.translate(
                                                                'Select_Trim'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        {/* <Select  className="form-control" name="city" values={["Select City"]} /> */}
                                                        <select
                                                            className="form-control"
                                                            name="newcity"
                                                            value={
                                                                this.state
                                                                    .selectedNewCity
                                                            }
                                                            onChange={
                                                                this
                                                                    .newCityChangeHandler
                                                            }
                                                        >
                                                            <option value="Select City">
                                                                {' '}
                                                                {[
                                                                    this.props.translator.translate(
                                                                        'Select_City'
                                                                    )
                                                                ]}
                                                            </option>
                                                            {this.state.cities.map(
                                                                city => (
                                                                    <option
                                                                        key={
                                                                            city
                                                                        }
                                                                        value={
                                                                            city
                                                                        }
                                                                    >
                                                                        {city}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.searchVehicle == true ? (
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    {/* <Select  className="form-control" name="city" values={["Select City"]} /> */}
                                                    <select
                                                        className="form-control"
                                                        name="newcity"
                                                        value={
                                                            this.state
                                                                .selectedNewCity
                                                        }
                                                        onChange={
                                                            this
                                                                .newCityChangeHandler
                                                        }
                                                    >
                                                        <option value="Select City">
                                                            {' '}
                                                            {[
                                                                this.props.translator.translate(
                                                                    'Select_City'
                                                                )
                                                            ]}
                                                        </option>
                                                        {this.state.cities.map(
                                                            city => (
                                                                <option
                                                                    key={city}
                                                                    value={city}
                                                                >
                                                                    {city}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {this.state.searchVehicle == true ? (
                                        <a
                                            href="#"
                                            role="button"
                                            className="link"
                                            onClick={this.searchVehicleHandler}
                                        >
                                            {this.props.translator.translate(
                                                'Or_Enter_Manually'
                                            )}
                                        </a>
                                    ) : (
                                        <div className="row">
                                            <div className="col">
                                                <div className="">
                                                    <a
                                                        href="#"
                                                        role="button"
                                                        className="link"
                                                        onClick={
                                                            this
                                                                .searchVehicleHandler
                                                        }
                                                    >
                                                        {this.props.translator.translate(
                                                            'Or_Select_A_Vehicle'
                                                        )}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-12">
                                            <FormErrors
                                                formErrors={
                                                    this.state.formErrors
                                                }
                                            />
                                            <div className="actions">
                                                <input
                                                    name="phone"
                                                    type="hidden"
                                                    id="newForm_phone"
                                                    value={
                                                        this.state.getPhone !==
                                                        undefined
                                                            ? this.state
                                                                  .getPhone
                                                            : ''
                                                    }
                                                />
                                                <input
                                                    type="hidden"
                                                    name="userType"
                                                    id="newForm_userType"
                                                    value={
                                                        this.state.userType !==
                                                        undefined
                                                            ? this.state
                                                                  .userType
                                                            : ''
                                                    }
                                                />
                                                <button
                                                    className="btn btn-block btn-primary"
                                                    id="check_price_new"
                                                    type="button"
                                                    onClick={
                                                        this.handleSubmitNew
                                                    }
                                                >
                                                    Check Price
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>

                    <div
                        className={`tab-pane fade show exchange-tab ${
                            this.state.currentTab == 'Exchange' ? 'active' : ''
                        } `}
                        id="exchange"
                        role="tabpanel"
                        aria-labelledby="exchange-tab"
                    >
                        {this.state.currentTab == 'Exchange' ? (
                            <div className="content p-0">
                                <p>
                                    <Text
                                        id="Exchange_desc"
                                        defaultMessage="Select the Vehicle you want to Exchange Your Current Vehicle With and Know The Price Difference."
                                    />
                                </p>
                                <form
                                    action={this.state.postResult}
                                    id="exchangeForm"
                                    onSubmit={this.handleSubmitExchange}
                                    method="POST"
                                >
                                    <input
                                        type="hidden"
                                        value="exchange"
                                        name="feature"
                                    />
                                    <p>
                                        <Text
                                            id="I_Want_to_Exchange"
                                            defaultMessage="I want to Exchange"
                                        />
                                        :
                                    </p>
                                    {this.state.searchVehicle == true ? (
                                        <div className="row">
                                            <div className="col">
                                                <VehicleSearch
                                                    clickSearchVehicle={
                                                        this
                                                            .searchVehicleHandler
                                                    }
                                                    iskmsdriven="Yes"
                                                    feature="exchange"
                                                    car_condition="used"
                                                    isCondition="No"
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <Select
                                                            className="form-control"
                                                            name="exchangecategory"
                                                            values={
                                                                this.state
                                                                    .categories
                                                            }
                                                            onChange={e => {
                                                                this.handleChangeExchange(
                                                                    e
                                                                );
                                                                this.handleChangeExchangeNewcategoryOn(
                                                                    e
                                                                );
                                                            }}
                                                            label={this.props.translator.translate(
                                                                'Select_Category'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <Select
                                                            className="form-control"
                                                            name="exchangemake"
                                                            onChange={
                                                                this
                                                                    .handleChangeExchange
                                                            }
                                                            label={this.props.translator.translate(
                                                                'Select_Make'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <Select
                                                            className="form-control"
                                                            name="exchangemodel"
                                                            onChange={
                                                                this
                                                                    .handleChangeExchange
                                                            }
                                                            label={this.props.translator.translate(
                                                                'Select_Model'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <Select
                                                            className="form-control"
                                                            name="exchangeyear"
                                                            onChange={
                                                                this
                                                                    .handleChangeExchange
                                                            }
                                                            label={this.props.translator.translate(
                                                                'Select_Year'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <Select
                                                            className="form-control"
                                                            name="exchangetrim"
                                                            label={this.props.translator.translate(
                                                                'Select_Trim'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group without-arrow">
                                                        <TextBox
                                                            disabled={
                                                                this.state
                                                                    .kms_disabled
                                                            }
                                                            className="form-control"
                                                            name="exchangekms_driven"
                                                            type="number"
                                                            placeHolder={this.props.translator.translate(
                                                                this.state
                                                                    .kms_placeHolder
                                                            )}
                                                            onChange={
                                                                this
                                                                    .handleInputChange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="">
                                                        <a
                                                            href="#"
                                                            role="button"
                                                            className="link"
                                                            onClick={
                                                                this
                                                                    .searchVehicleHandler
                                                            }
                                                        >
                                                            {this.props.translator.translate(
                                                                'Or_Select_A_Vehicle'
                                                            )}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="exchange-radio-btns">
                                        <label>
                                            {' '}
                                            <Text
                                                id="With"
                                                defaultMessage="With"
                                            />
                                            :
                                        </label>
                                        <div className="custom-control custome-controls-success custom-radio custom-control-inline">
                                            <input
                                                id="newExchange"
                                                onClick={this.handleExchangeToggle.bind(
                                                    this
                                                )}
                                                onChange={
                                                    this
                                                        .handleChangeExchangeNewcategory
                                                }
                                                type="radio"
                                                className="custom-control-input"
                                                name="exchange_type"
                                                value="exchange_with_new"
                                                checked={
                                                    this.state.exchange_type ==
                                                    false
                                                        ? 'checked'
                                                        : ''
                                                }
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="newExchange"
                                            >
                                                {' '}
                                                <Text
                                                    id="New"
                                                    defaultMessage="New"
                                                />
                                                :
                                            </label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input
                                                id="usedExchange"
                                                onClick={this.handleExchangeToggle.bind(
                                                    this
                                                )}
                                                onChange={
                                                    this
                                                        .handleChangeExchangeUsedcategory
                                                }
                                                type="radio"
                                                className="custom-control-input"
                                                name="exchange_type"
                                                value="exchange_with_used"
                                                checked={
                                                    this.state.exchange_type ==
                                                    true
                                                        ? 'checked'
                                                        : ''
                                                }
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="usedExchange"
                                            >
                                                {' '}
                                                <Text
                                                    id="Used"
                                                    defaultMessage="Used"
                                                />
                                                :
                                            </label>
                                        </div>
                                    </div>

                                    <div
                                        className="excnew"
                                        style={{
                                            display:
                                                this.state.exchange_type ==
                                                false
                                                    ? 'block'
                                                    : 'none'
                                        }}
                                    >
                                        {this.state.searchVehicle == true ? (
                                            <div className="row">
                                                <div className="col">
                                                    <VehicleSearch
                                                        clickSearchVehicle={
                                                            this
                                                                .searchVehicleHandler
                                                        }
                                                        iskmsdriven="No"
                                                        feature="exchange"
                                                        car_condition="new"
                                                        isCondition="Yes"
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <Select
                                                                className="form-control"
                                                                name="exchangemakenew"
                                                                onChange={
                                                                    this
                                                                        .handleChangeExchangeNew
                                                                }
                                                                label={this.props.translator.translate(
                                                                    'Select_Make'
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <Select
                                                                className="form-control"
                                                                name="exchangemodelnew"
                                                                onChange={
                                                                    this
                                                                        .handleChangeExchangeNew
                                                                }
                                                                label={this.props.translator.translate(
                                                                    'Select_Model'
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <Select
                                                                className="form-control"
                                                                name="exchangetrimnew"
                                                                label={this.props.translator.translate(
                                                                    'Select_Trim'
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="">
                                                            <a
                                                                href="#"
                                                                role="button"
                                                                className="link"
                                                                onClick={
                                                                    this
                                                                        .searchVehicleHandler
                                                                }
                                                            >
                                                                {this.props.translator.translate(
                                                                    'Or_Select_A_Vehicle'
                                                                )}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="excused"
                                        style={{
                                            display:
                                                this.state.exchange_type == true
                                                    ? 'block'
                                                    : 'none'
                                        }}
                                    >
                                        {this.state.searchVehicle == true ? (
                                            <div className="row">
                                                <div className="col">
                                                    <VehicleSearch
                                                        clickSearchVehicle={
                                                            this
                                                                .searchVehicleHandler
                                                        }
                                                        iskmsdriven="Yes"
                                                        feature="exchange"
                                                        car_condition="used"
                                                        isCondition="Yes"
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <Select
                                                                className="form-control"
                                                                name="exchangemakeused"
                                                                onChange={
                                                                    this
                                                                        .handleChangeExchangeUsed
                                                                }
                                                                label={this.props.translator.translate(
                                                                    'Select_Make'
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <Select
                                                                className="form-control"
                                                                name="exchangemodelused"
                                                                onChange={
                                                                    this
                                                                        .handleChangeExchangeUsed
                                                                }
                                                                label={this.props.translator.translate(
                                                                    'Select_Model'
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <Select
                                                                className="form-control"
                                                                name="exchangeyearused"
                                                                onChange={
                                                                    this
                                                                        .handleChangeExchangeUsed
                                                                }
                                                                label={this.props.translator.translate(
                                                                    'Select_Year'
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <Select
                                                                className="form-control"
                                                                name="exchangetrimused"
                                                                label={this.props.translator.translate(
                                                                    'Select_Trim'
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group without-arrow">
                                                            <TextBox
                                                                disabled={
                                                                    this.state
                                                                        .kms_disabled
                                                                }
                                                                className="form-control"
                                                                name="exchangekms_drivenused"
                                                                type="number"
                                                                placeHolder={this.props.translator.translate(
                                                                    this.state
                                                                        .kms_placeHolder
                                                                )}
                                                                onChange={
                                                                    this
                                                                        .handleInputChange
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="">
                                                            <a
                                                                href="#"
                                                                role="button"
                                                                className="link"
                                                                onClick={
                                                                    this
                                                                        .searchVehicleHandler
                                                                }
                                                            >
                                                                {this.props.translator.translate(
                                                                    'Or_Select_A_Vehicle'
                                                                )}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <FormErrors
                                                formErrors={
                                                    this.state.formErrors
                                                }
                                            />
                                            <div className="actions">
                                                <input
                                                    name="phone"
                                                    type="hidden"
                                                    id="exchangeForm_phone"
                                                    value={
                                                        this.state.getPhone !==
                                                        undefined
                                                            ? this.state
                                                                  .getPhone
                                                            : ''
                                                    }
                                                />
                                                <input
                                                    type="hidden"
                                                    name="userType"
                                                    id="exchangeForm_userType"
                                                    value={
                                                        this.state.userType !==
                                                        undefined
                                                            ? this.state
                                                                  .userType
                                                            : ''
                                                    }
                                                />
                                                <button
                                                    className="btn btn-block btn-primary"
                                                    id="check_price_exchange"
                                                    type="button"
                                                    onClick={
                                                        this
                                                            .handleSubmitExchange
                                                    }
                                                >
                                                    {this.props.translator.translate(
                                                        'Check_Exchange_Price'
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div
                        className={`tab-pane fade price-tab  ${
                            this.state.currentTab == 'Future Price'
                                ? 'active show'
                                : ''
                        } `}
                        id="price"
                        role="tabpanel"
                        aria-labelledby="price-tab"
                    >
                        {this.state.currentTab == 'Future Price' ? (
                            <div className="content p-0">
                                <p>
                                    <Text
                                        id="Future_Price_desc"
                                        defaultMessage="Know the Estimated Re-Selling Price for your vehicle in coming years."
                                    />
                                </p>
                                <ul
                                    className="nav nav-pills nav-fill mb-3"
                                    role="tablist"
                                >
                                    <li className="nav-item">
                                        <a
                                            className="nav-link active"
                                            id="new-price"
                                            data-toggle="tab"
                                            href="#pills-new"
                                            role="tab"
                                            aria-controls="pills-new"
                                            aria-selected="true"
                                        >
                                            <Text
                                                id="For_New"
                                                defaultMessage="For New"
                                            />
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className="nav-link"
                                            id="used-price"
                                            data-toggle="tab"
                                            href="#pills-used"
                                            role="tab"
                                            aria-controls="pills-used"
                                            aria-selected="false"
                                        >
                                            <Text
                                                id="For_Used"
                                                defaultMessage="For Used"
                                            />
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-new"
                                        role="tabpanel"
                                        aria-labelledby="new-price"
                                    >
                                        <form
                                            action={this.state.postResult}
                                            id="featureFormNew"
                                            onSubmit={
                                                this.handleSubmitFeatureNew
                                            }
                                            method="POST"
                                        >
                                            <input
                                                type="hidden"
                                                name="featuretranstypenew"
                                                id="featuretranstypenew"
                                                value="new"
                                            />
                                            <input
                                                type="hidden"
                                                name="feature"
                                                id="feature"
                                                value="future"
                                            />
                                            {this.state.searchVehicle ==
                                            true ? (
                                                <div className="row">
                                                    <div className="col">
                                                        <VehicleSearch
                                                            clickSearchVehicle={
                                                                this
                                                                    .searchVehicleHandler
                                                            }
                                                            iskmsdriven="No"
                                                            feature="feature"
                                                            car_condition="new"
                                                            isCondition="Yes"
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="featurecategorynew"
                                                                    values={
                                                                        this
                                                                            .state
                                                                            .categories
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleChangeFeatureNew
                                                                    }
                                                                    label={this.props.translator.translate(
                                                                        'Select_Category'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="featuremakenew"
                                                                    onChange={
                                                                        this
                                                                            .handleChangeFeatureNew
                                                                    }
                                                                    label={this.props.translator.translate(
                                                                        'Select_Make'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="featuremodelnew"
                                                                    onChange={
                                                                        this
                                                                            .handleChangeFeatureNew
                                                                    }
                                                                    label={this.props.translator.translate(
                                                                        'Select_Model'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="featuretrimnew"
                                                                    label={this.props.translator.translate(
                                                                        'Select_Trim'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        {/* <Select  className="form-control" required="required" name="fatureexpected-yearnew" values={["Resale"]}  /> */}
                                                        <select
                                                            className="form-control"
                                                            name="featureexpectedresalnew"
                                                        >
                                                            <option value="Resale">
                                                                {this.props.translator.translate(
                                                                    'Year_of_Resale'
                                                                )}
                                                            </option>
                                                            {this.state.expYear.map(
                                                                expyr => (
                                                                    <option
                                                                        key={
                                                                            expyr
                                                                        }
                                                                        value={
                                                                            expyr
                                                                        }
                                                                    >
                                                                        {expyr}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group without-arrow">
                                                        <TextBox
                                                            disabled={
                                                                this.state
                                                                    .kms_disabled
                                                            }
                                                            className="form-control"
                                                            name="featureexpected_kms_drivennew"
                                                            type="number"
                                                            placeHolder={
                                                                this.state
                                                                    .excepted_kms_placeHolder
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleInputChange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.searchVehicle ==
                                            true ? (
                                                <a
                                                    href="#"
                                                    role="button"
                                                    className="link"
                                                    onClick={
                                                        this
                                                            .searchVehicleHandler
                                                    }
                                                >
                                                    {this.props.translator.translate(
                                                        'Or_Enter_Manually'
                                                    )}
                                                </a>
                                            ) : (
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="">
                                                            <a
                                                                href="#"
                                                                role="button"
                                                                className="link"
                                                                onClick={
                                                                    this
                                                                        .searchVehicleHandler
                                                                }
                                                            >
                                                                {this.props.translator.translate(
                                                                    'Or_Select_A_Vehicle'
                                                                )}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row">
                                                <div className="col-12">
                                                    <FormErrors
                                                        formErrors={
                                                            this.state
                                                                .formErrors
                                                        }
                                                    />
                                                    <div className="actions">
                                                        <input
                                                            name="phone"
                                                            type="hidden"
                                                            id="featureFormNew_phone"
                                                            value={
                                                                this.state
                                                                    .getPhone !==
                                                                undefined
                                                                    ? this.state
                                                                          .getPhone
                                                                    : ''
                                                            }
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name="userType"
                                                            id="featureFormNew_userType"
                                                            value={
                                                                this.state
                                                                    .userType !==
                                                                undefined
                                                                    ? this.state
                                                                          .userType
                                                                    : ''
                                                            }
                                                        />
                                                        <button
                                                            className="btn btn-block btn-primary"
                                                            id="check_price_future"
                                                            type="button"
                                                            onClick={
                                                                this
                                                                    .handleSubmitFeatureNew
                                                            }
                                                        >
                                                            {this.props.translator.translate(
                                                                'Check_Future_Estimate'
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div
                                        className="tab-pane fade used-price"
                                        id="pills-used"
                                        role="tabpanel"
                                        aria-labelledby="used-price"
                                    >
                                        <form
                                            action={this.state.postResult}
                                            id="featureFormUsed"
                                            onSubmit={
                                                this.handleSubmitFeatureUsed
                                            }
                                            method="POST"
                                        >
                                            {/* <div className="row">
                      <div className="col text-center">
                        <div className="form-group">
                         <div className="custom-control custome-controls-success custom-radio">
                            <input id="checkIndiVal" type="radio" className="custom-control-input" name="customer_type_value" value="individual" />
                            <label className="custom-control-label" htmlFor="checkIndiVal" >{this.state.buyText} Individual</label>
                          </div>
                        </div>
                      </div>
                     <div className="col text-center">
                        <div className="form-group">
                        <div className="custom-control custom-radio">
                          <input id="checkDealVal" type="radio" className="custom-control-input" name="customer_type_value" value="dealer" />
                           <label className="custom-control-label" htmlFor="checkDealVal" >{this.state.buyText} Dealer</label>
                        </div>
                       </div>
                      </div>
                    </div> */}
                                            <input
                                                type="hidden"
                                                id="transaction_type_used"
                                                name="transaction_type_used"
                                                value={
                                                    this.state.transaction_type
                                                }
                                            />
                                            <input
                                                type="hidden"
                                                id="customer_type_value"
                                                name="transaction_type_used"
                                                value="dealer"
                                            />
                                            <input
                                                type="hidden"
                                                id="is_taxi"
                                                name="is_taxi"
                                                value={this.state.isTaxi}
                                            />
                                            <input
                                                type="hidden"
                                                name="feature"
                                                value="future"
                                            />
                                            {this.state.searchVehicle ==
                                            true ? (
                                                <div className="row">
                                                    <div className="col">
                                                        <VehicleSearch
                                                            clickSearchVehicle={
                                                                this
                                                                    .searchVehicleHandler
                                                            }
                                                            iskmsdriven="Yes"
                                                            feature="feature"
                                                            car_condition="used"
                                                            isCondition="Yes"
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="featurecategoryused"
                                                                    values={
                                                                        this
                                                                            .state
                                                                            .categories
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleChangeFeatureUsed
                                                                    }
                                                                    label={this.props.translator.translate(
                                                                        'Select_Category'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="featuremakeused"
                                                                    onChange={
                                                                        this
                                                                            .handleChangeFeatureUsed
                                                                    }
                                                                    label={this.props.translator.translate(
                                                                        'Select_Make'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="featuremodelused"
                                                                    onChange={
                                                                        this
                                                                            .handleChangeFeatureUsed
                                                                    }
                                                                    label={this.props.translator.translate(
                                                                        'Select_Model'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="featureyearused"
                                                                    onChange={
                                                                        this
                                                                            .handleChangeFeatureUsed
                                                                    }
                                                                    label={this.props.translator.translate(
                                                                        'Select_Year'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="featuretrimused"
                                                                    label={this.props.translator.translate(
                                                                        'Select_Trim'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group without-arrow">
                                                                <TextBox
                                                                    disabled={
                                                                        this
                                                                            .state
                                                                            .kms_disabled
                                                                    }
                                                                    className="form-control"
                                                                    name="feature_kms_drivenused"
                                                                    type="number"
                                                                    placeHolder={this.props.translator.translate(
                                                                        this
                                                                            .state
                                                                            .kms_placeHolder
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        {/* <Select  className="form-control" name="featureexpectedresaleused" values={["Expected Year of Resale"]} onChange={this.handleChange} /> */}
                                                        <select
                                                            className="form-control"
                                                            name="featureexpectedresaleused"
                                                        >
                                                            <option value="Resale">
                                                                {this.props.translator.translate(
                                                                    'Year_of_Resale'
                                                                )}
                                                            </option>
                                                            {this.state.expYear.map(
                                                                expyr => (
                                                                    <option
                                                                        key={
                                                                            expyr
                                                                        }
                                                                        value={
                                                                            expyr
                                                                        }
                                                                    >
                                                                        {expyr}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group without-arrow">
                                                        <TextBox
                                                            disabled={
                                                                this.state
                                                                    .kms_disabled
                                                            }
                                                            className="form-control"
                                                            name="featureexpected_kms_drivenused"
                                                            type="number"
                                                            placeHolder={
                                                                this.state
                                                                    .excepted_kms_placeHolder
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleInputChange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.searchVehicle ==
                                            true ? (
                                                <a
                                                    href="#"
                                                    role="button"
                                                    className="link"
                                                    onClick={
                                                        this
                                                            .searchVehicleHandler
                                                    }
                                                >
                                                    {this.props.translator.translate(
                                                        'Or_Enter_Manually'
                                                    )}
                                                </a>
                                            ) : (
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="">
                                                            <a
                                                                href="#"
                                                                role="button"
                                                                className="link"
                                                                onClick={
                                                                    this
                                                                        .searchVehicleHandler
                                                                }
                                                            >
                                                                {this.props.translator.translate(
                                                                    'Or_Select_A_Vehicle'
                                                                )}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row">
                                                <div className="col-12">
                                                    <FormErrors
                                                        formErrors={
                                                            this.state
                                                                .formErrors
                                                        }
                                                    />
                                                    <div className="actions">
                                                        <input
                                                            name="phone"
                                                            type="hidden"
                                                            id="featureFormUsed_phone"
                                                            value={
                                                                this.state
                                                                    .getPhone !==
                                                                undefined
                                                                    ? this.state
                                                                          .getPhone
                                                                    : ''
                                                            }
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name="userType"
                                                            id="featureFormUsed_userType"
                                                            value={
                                                                this.state
                                                                    .userType !==
                                                                undefined
                                                                    ? this.state
                                                                          .userType
                                                                    : ''
                                                            }
                                                        />
                                                        <button
                                                            className="btn btn-block btn-primary"
                                                            id="check_price_future"
                                                            type="button"
                                                            onClick={
                                                                this
                                                                    .handleSubmitFeatureUsed
                                                            }
                                                        >
                                                            {this.props.translator.translate(
                                                                'Check_Future_Estimate'
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div
                        className={`tab-pane fade  ${
                            this.state.currentTab == 'Residual'
                                ? 'active show'
                                : ''
                        } `}
                        id="lease"
                        role="tabpanel"
                        aria-labelledby="lease-tab"
                    >
                        {this.state.currentTab == 'Residual' ? (
                            <div className="content p-0">
                                <p>
                                    <Text
                                        id="Residual_desc"
                                        defaultMessage="Residual Value of the vehicle provides an estimate of the worth of the vehicle at the end of the lease."
                                    />
                                </p>
                                <ul
                                    className="nav nav-pills nav-fill mb-3"
                                    role="tablist"
                                >
                                    <li className="nav-item">
                                        <a
                                            className="nav-link active"
                                            id="new-price"
                                            data-toggle="tab"
                                            href="#pillss-new"
                                            role="tab"
                                            aria-controls="pillss-new"
                                            aria-selected="true"
                                        >
                                            <Text
                                                id="For_New"
                                                defaultMessage="For New"
                                            />
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className="nav-link"
                                            id="used-price"
                                            data-toggle="tab"
                                            href="#pillss-used"
                                            role="tab"
                                            aria-controls="pillss-used"
                                            aria-selected="false"
                                        >
                                            <Text
                                                id="For_Used"
                                                defaultMessage="For Used"
                                            />
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div
                                        className="tab-pane fade show active"
                                        id="pillss-new"
                                        role="tabpanel"
                                        aria-labelledby="new-price"
                                    >
                                        <form
                                            action={this.state.postResult}
                                            id="leaseFormNew"
                                            onSubmit={this.handleSubmitLeaseNew}
                                            method="POST"
                                        >
                                            <input
                                                type="hidden"
                                                name="feature"
                                                id="feature"
                                                value="lease"
                                            />
                                            <input
                                                type="hidden"
                                                name="vehicletypenew"
                                                id="vehicletypenew"
                                                value="new"
                                            />
                                            {this.state.searchVehicle ==
                                            true ? (
                                                <div className="row">
                                                    <div className="col">
                                                        <VehicleSearch
                                                            clickSearchVehicle={
                                                                this
                                                                    .searchVehicleHandler
                                                            }
                                                            iskmsdriven="No"
                                                            feature="lease"
                                                            car_condition="new"
                                                            isCondition="Yes"
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="leasecategorynew"
                                                                    values={
                                                                        this
                                                                            .state
                                                                            .categories
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleChangeLeaseNew
                                                                    }
                                                                    label={this.props.translator.translate(
                                                                        'Select_Category'
                                                                    )}
                                                                />

                                                                <input
                                                                    type="hidden"
                                                                    name="feature"
                                                                    id="feature"
                                                                    value="lease"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="leasemakenew"
                                                                    onChange={
                                                                        this
                                                                            .handleChangeLeaseNew
                                                                    }
                                                                    label={this.props.translator.translate(
                                                                        'Select_Make'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="leasemodelnew"
                                                                    onChange={
                                                                        this
                                                                            .handleChangeLeaseNew
                                                                    }
                                                                    label={this.props.translator.translate(
                                                                        'Select_Model'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="leasetrimnew"
                                                                    onChange={
                                                                        this
                                                                            .handleChangeLeaseNew
                                                                    }
                                                                    label={this.props.translator.translate(
                                                                        'Select_Trim'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group without-arrow">
                                                        <TextBox
                                                            className="form-control"
                                                            name="leasetenurenew"
                                                            type="number"
                                                            placeHolder={[
                                                                this.props.translator.translate(
                                                                    'Tenure_(Month)'
                                                                )
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group without-arrow">
                                                        <TextBox
                                                            disabled={
                                                                this.state
                                                                    .kms_disabled
                                                            }
                                                            className="form-control"
                                                            name="leasekms_driven_monthnew"
                                                            type="number"
                                                            placeHolder={[
                                                                this.props.translator.translate(
                                                                    'Km_Driven/Month'
                                                                )
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.searchVehicle ==
                                            true ? (
                                                <a
                                                    href="#"
                                                    role="button"
                                                    className="link"
                                                    onClick={
                                                        this
                                                            .searchVehicleHandler
                                                    }
                                                >
                                                    {this.props.translator.translate(
                                                        'Or_Enter_Manually'
                                                    )}
                                                </a>
                                            ) : (
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="">
                                                            <a
                                                                href="#"
                                                                role="button"
                                                                className="link"
                                                                onClick={
                                                                    this
                                                                        .searchVehicleHandler
                                                                }
                                                            >
                                                                {this.props.translator.translate(
                                                                    'Or_Select_A_Vehicle'
                                                                )}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row">
                                                <div className="col-12">
                                                    <FormErrors
                                                        formErrors={
                                                            this.state
                                                                .formErrors
                                                        }
                                                    />
                                                    <div className="actions">
                                                        <input
                                                            name="phone"
                                                            type="hidden"
                                                            id="leaseFormNew_phone"
                                                            value={
                                                                this.state
                                                                    .getPhone !==
                                                                undefined
                                                                    ? this.state
                                                                          .getPhone
                                                                    : ''
                                                            }
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name="userType"
                                                            id="leaseFormNew_userType"
                                                            value={
                                                                this.state
                                                                    .userType !==
                                                                undefined
                                                                    ? this.state
                                                                          .userType
                                                                    : ''
                                                            }
                                                        />
                                                        <button
                                                            className="btn btn-block btn-primary"
                                                            id="check_price_lease"
                                                            type="button"
                                                            onClick={
                                                                this
                                                                    .handleSubmitLeaseNew
                                                            }
                                                        >
                                                            {' '}
                                                            {this.props.translator.translate(
                                                                'Check_Residual_Estimate'
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div
                                        className="tab-pane fade "
                                        id="pillss-used"
                                        role="tabpanel"
                                        aria-labelledby="used-price"
                                    >
                                        <form
                                            action={this.state.postResult}
                                            id="leaseForm"
                                            onSubmit={this.handleSubmitLease}
                                            method="POST"
                                        >
                                            <input
                                                type="hidden"
                                                value="lease"
                                                name="feature"
                                            />
                                            <input
                                                type="hidden"
                                                value="used"
                                                name="vehicletype"
                                            />
                                            {this.state.searchVehicle ==
                                            true ? (
                                                <div className="row">
                                                    <div className="col">
                                                        <VehicleSearch
                                                            clickSearchVehicle={
                                                                this
                                                                    .searchVehicleHandler
                                                            }
                                                            iskmsdriven="Yes"
                                                            feature="lease"
                                                            car_condition="used"
                                                            isCondition="No"
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="leasecategory"
                                                                    values={
                                                                        this
                                                                            .state
                                                                            .categories
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleChangeLease
                                                                    }
                                                                    label={this.props.translator.translate(
                                                                        'Select_Category'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="leasemake"
                                                                    onChange={
                                                                        this
                                                                            .handleChangeLease
                                                                    }
                                                                    label={this.props.translator.translate(
                                                                        'Select_Make'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="leasemodel"
                                                                    onChange={
                                                                        this
                                                                            .handleChangeLease
                                                                    }
                                                                    label={this.props.translator.translate(
                                                                        'Select_Model'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="leaseyear"
                                                                    onChange={
                                                                        this
                                                                            .handleChangeLease
                                                                    }
                                                                    label={this.props.translator.translate(
                                                                        'Select_Year'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <Select
                                                                    className="form-control"
                                                                    name="leasetrim"
                                                                    label={this.props.translator.translate(
                                                                        'Select_Trim'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group without-arrow">
                                                                <TextBox
                                                                    disabled={
                                                                        this
                                                                            .state
                                                                            .kms_disabled
                                                                    }
                                                                    className="form-control"
                                                                    name="leasekms_driven"
                                                                    type="number"
                                                                    placeHolder={this.props.translator.translate(
                                                                        this
                                                                            .state
                                                                            .kms_placeHolder
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group without-arrow">
                                                        <TextBox
                                                            className="form-control"
                                                            name="leasetenure"
                                                            type="number"
                                                            placeHolder={[
                                                                this.props.translator.translate(
                                                                    'Tenure_(Month)'
                                                                )
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group without-arrow">
                                                        <TextBox
                                                            disabled={
                                                                this.state
                                                                    .kms_disabled
                                                            }
                                                            className="form-control"
                                                            name="leasekms_driven_month"
                                                            type="number"
                                                            placeHolder={
                                                                this.state
                                                                    .kms_month_placeHolder
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="col">
                    <div className="form-group">

                      <select className="form-control" name="leasecity">
                      <option value='Select City'>Select City </option>
                        { this.state.cities.map((city) => <option key={city} value={city}>{city}</option>) }
                      </select>
                    </div>
                  </div> */}
                                            </div>
                                            {this.state.searchVehicle ==
                                            true ? (
                                                <a
                                                    href="#"
                                                    role="button"
                                                    className="link"
                                                    onClick={
                                                        this
                                                            .searchVehicleHandler
                                                    }
                                                >
                                                    {this.props.translator.translate(
                                                        'Or_Enter_Manually'
                                                    )}
                                                </a>
                                            ) : (
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="">
                                                            <a
                                                                href="#"
                                                                role="button"
                                                                className="link"
                                                                onClick={
                                                                    this
                                                                        .searchVehicleHandler
                                                                }
                                                            >
                                                                {this.props.translator.translate(
                                                                    'Or_Select_A_Vehicle'
                                                                )}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="row">
                                                <div className="col-12">
                                                    <FormErrors
                                                        formErrors={
                                                            this.state
                                                                .formErrors
                                                        }
                                                    />
                                                    <div className="actions">
                                                        <input
                                                            name="phone"
                                                            type="hidden"
                                                            id="leaseForm_phone"
                                                            value={
                                                                this.state
                                                                    .getPhone !==
                                                                undefined
                                                                    ? this.state
                                                                          .getPhone
                                                                    : ''
                                                            }
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name="userType"
                                                            id="leaseForm_userType"
                                                            value={
                                                                this.state
                                                                    .userType !==
                                                                undefined
                                                                    ? this.state
                                                                          .userType
                                                                    : ''
                                                            }
                                                        />
                                                        <button
                                                            className="btn btn-block btn-primary"
                                                            type="button"
                                                            id="check_price_lease"
                                                            onClick={
                                                                this
                                                                    .handleSubmitLease
                                                            }
                                                        >
                                                            {this.props.translator.translate(
                                                                'Check_Residual_Estimate'
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>

                <ErrorModal2 errors={this.state.errors} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        ENV_WEB_URL: state.initState.WEB_URL ? state.initState.WEB_URL : '',
        locationCity: state.header.selectedLocation
    };
}
export default withLocalizePath(
    withTranslator(connect(mapStateToProps)(Vehicle))
);
